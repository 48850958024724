import React from "react"

import "../CSS/PricingPage.css";
import Navbar1 from "../Components/Header";
import Footer1 from "../Components/Footer1";
import Loader from "../Components/Loader";

export const Pricing = () => {
    return (
        <>
            <Loader/>
            <div className="Pricingpage">
            <Navbar1/>
            <div className="container">
        <div id="webcrumbs1">
            <div className="w-full flex justify-center">
                <div className=" from-indigo-50 to-blue-50 rounded-xl overflow-hidden">
                   

                    <main className="px-8 py-10">
                        <section className="mb-16 relative overflow-hidden">
                            <div className="absolute top-[-50px] right-[-50px] w-[300px] h-[300px] from-indigo-300/30 to-purple-300/30 rounded-full blur-3xl animate-pulse"></div>
                            <div
                                className="absolute bottom-[-80px] left-[-80px] w-[250px] h-[250px]  from-blue-300/30 to-cyan-300/30 rounded-full blur-3xl animate-pulse"
                                style={{animationDelay: "1.5s"}}
                            ></div>

                            <div className="text-center mb-12 relative">
                                <div className="inline-block">
                                    <span className="text-sm font-medium py-1 px-3 rounded-full bg-indigo-100 text-indigo-800 mb-3 inline-block">
                                        Pricing
                                    </span>
                                    <h2 className="text-4xl md:text-5xl font-bold from-indigo-600 via-blue-500 to-purple-600 bg-clip-text text-transparent pb-2 relative">
                                        Our IT Services Plans
                                        <div className="h-1 w-24 from-indigo-600 to-purple-600 rounded-full absolute bottom-0 left-1/2 transform -translate-x-1/2 animate-pulse"></div>
                                    </h2>
                                </div>
                                <p className="mt-4 max-w-2xl mx-auto">
                                    Choose the perfect plan for your business needs and scale as you grow
                                </p>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 relative z-10">
                                <div className="bg-white bg-opacity-80 backdrop-blur-sm p-8 rounded-2xl shadow-xl border border-indigo-100 transform transition-all hover:scale-[1.02] hover:shadow-2xl relative overflow-hidden group">
                                    <div className="absolute inset-0 bg-gradient-to-br from-indigo-500/5 to-purple-500/5 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                                    <div className="absolute top-0 left-0 h-1 w-0 bg-gradient-to-r from-indigo-500 to-purple-500 group-hover:w-full transition-all duration-700"></div>

                                    <span className="flex items-center justify-center h-16 w-16 rounded-full bg-gradient-to-r from-indigo-500 to-purple-600 text-white mb-6 shadow-lg transform transition-transform group-hover:rotate-12">
                                        <span className="material-symbols-outlined text-3xl">code</span>
                                    </span>

                                    <h3 className="text-2xl font-bold mb-2 from-indigo-600 to-purple-600 bg-clip-text text-transparent">
                                        Product Development
                                    </h3>
                                    <div className="flex items-baseline mb-6">
                                        <span className="text-4xl font-bold"> $ 25 </span>
                                        <span className="text-gray-500 ml-2"> / Resource</span>
                                    </div>

                                    <ul className="space-y-3 mb-8">
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-indigo-100 text-indigo-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">Full-stack development services</p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-indigo-100 text-indigo-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">Agile methodology implementation</p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-indigo-100 text-indigo-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">Quality assurance & testing</p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-indigo-100 text-indigo-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">Continuous integration/deployment</p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-indigo-100 text-indigo-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">3 months of maintenance included</p>
                                        </li>
                                    </ul>

                                    <button className="w-full py-3 bg-gradient-to-r from-indigo-600 to-purple-600 text-white font-medium rounded-lg shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1">
                                        Choose Plan
                                    </button>
                                </div>

                                <div className="bg-white bg-opacity-80 backdrop-blur-sm p-8 rounded-2xl shadow-xl border border-blue-100 transform transition-all hover:scale-[1.03] hover:shadow-2xl relative overflow-hidden group z-20">
                                    <div className="absolute -top-6 -right-6 h-24 w-24 from-blue-500 to-purple-500 rotate-45"></div>
                                    {/* <div className="absolute top-4 right-4 z-10">
                                        <span className="text-white font-semibold text-sm py-1 px-3 rounded-full bg-opacity-80 backdrop-blur-sm transform -rotate-12">
                                            Most Popular
                                        </span>
                                    </div> */}
                                    <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-cyan-500/5 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                                    <div className="absolute top-0 left-0 h-1 w-0 bg-gradient-to-r from-blue-500 to-cyan-500 group-hover:w-full transition-all duration-700"></div>

                                    <span className="flex items-center justify-center h-16 w-16 rounded-full bg-gradient-to-r from-blue-500 to-cyan-600 text-white mb-6 shadow-lg transform transition-transform group-hover:rotate-12">
                                        <span className="material-symbols-outlined text-3xl">support_agent</span>
                                    </span>

                                    <h3 className="text-2xl font-bold mb-2 from-blue-600 to-cyan-600 bg-clip-text text-transparent">
                                        Consulting
                                    </h3>
                                    <div className="flex items-baseline mb-6">
                                        <span className="text-4xl font-bold">$ 30</span>
                                        <span className="text-gray-500 ml-2"> / Resource</span>
                                    </div>

                                    <ul className="space-y-3 mb-8">
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">IT strategy development</p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">System architecture design</p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">Business process optimization</p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">Performance evaluation</p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">24/7 priority support access</p>
                                        </li>
                                    </ul>

                                    <button className="w-full py-3 bg-gradient-to-r from-blue-600 to-cyan-600 text-white font-medium rounded-lg shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1">
                                        Choose Plan
                                    </button>
                                </div>

                                <div className="bg-white bg-opacity-80 backdrop-blur-sm p-8 rounded-2xl shadow-xl border border-purple-100 transform transition-all hover:scale-[1.02] hover:shadow-2xl relative overflow-hidden group">
                                    <div className="absolute inset-0 bg-gradient-to-br from-purple-500/5 to-indigo-500/5 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                                    <div className="absolute top-0 left-0 h-1 w-0 bg-gradient-to-r from-purple-500 to-indigo-500 group-hover:w-full transition-all duration-700"></div>

                                    <span className="flex items-center justify-center h-16 w-16 rounded-full bg-gradient-to-r from-purple-500 to-indigo-600 text-white mb-6 shadow-lg transform transition-transform group-hover:rotate-12">
                                        <span className="material-symbols-outlined text-3xl">brush</span>
                                    </span>

                                    <h3 className="text-2xl font-bold mb-2 from-purple-600 to-indigo-600 bg-clip-text text-transparent">
                                        UI/UX Designing
                                    </h3>
                                    <div className="flex items-baseline mb-6">
                                        <span className="text-4xl font-bold">$ 25</span>
                                        <span className="text-gray-500 ml-2"> / Resource</span>
                                    </div>

                                    <ul className="space-y-3 mb-8">
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-purple-100 text-purple-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">User research & analysis</p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-purple-100 text-purple-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">Wireframing & prototyping</p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-purple-100 text-purple-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">Visual design & branding</p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-purple-100 text-purple-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">Usability testing</p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-purple-100 text-purple-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">Unlimited revisions</p>
                                        </li>
                                    </ul>

                                    <button className="w-full py-3 bg-gradient-to-r from-purple-600 to-indigo-600 text-white font-medium rounded-lg shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1">
                                        Choose Plan
                                    </button>
                                </div>
                            </div>

                            <div className="mt-16 grid grid-cols-1 md:grid-cols-2 gap-8">
                                <div className="bg-white bg-opacity-80 backdrop-blur-sm p-8 rounded-2xl shadow-xl border border-cyan-100 transform transition-all hover:scale-[1.02] hover:shadow-2xl relative overflow-hidden group">
                                    <div className="absolute inset-0 bg-gradient-to-br from-cyan-500/5 to-blue-500/5 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                                    <div className="absolute top-0 left-0 h-1 w-0 bg-gradient-to-r from-cyan-500 to-blue-500 group-hover:w-full transition-all duration-700"></div>

                                    <span className="flex items-center justify-center h-16 w-16 rounded-full bg-gradient-to-r from-cyan-500 to-blue-600 text-white mb-6 shadow-lg transform transition-transform group-hover:rotate-12">
                                        <span className="material-symbols-outlined text-3xl">smart_toy</span>
                                    </span>

                                    <h3 className="text-2xl font-bold mb-2 from-cyan-600 to-blue-600 bg-clip-text text-transparent">
                                        AI Agent Building
                                    </h3>
                                    <div className="flex items-baseline mb-6">
                                        <span className="text-4xl font-bold">$ 30</span>
                                        <span className="text-gray-500 ml-2"> / Resource</span>
                                    </div>

                                    <ul className="space-y-3 mb-8">
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-cyan-100 text-cyan-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">Custom AI agent development</p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-cyan-100 text-cyan-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">Natural language processing</p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-cyan-100 text-cyan-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">Machine learning model training</p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-cyan-100 text-cyan-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">API integration & deployment</p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-cyan-100 text-cyan-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">6 months of maintenance & updates</p>
                                        </li>
                                    </ul>

                                    <button className="w-full py-3 bg-gradient-to-r from-cyan-600 to-blue-600 text-white font-medium rounded-lg shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1">
                                        Choose Plan
                                    </button>
                                </div>

                                <div className="bg-white bg-opacity-80 backdrop-blur-sm p-8 rounded-2xl shadow-xl border border-green-100 transform transition-all hover:scale-[1.02] hover:shadow-2xl relative overflow-hidden group">
                                    <div className="absolute inset-0 bg-gradient-to-br from-green-500/5 to-teal-500/5 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                                    <div className="absolute top-0 left-0 h-1 w-0 bg-gradient-to-r from-green-500 to-teal-500 group-hover:w-full transition-all duration-700"></div>

                                    <span className="flex items-center justify-center h-16 w-16 rounded-full bg-gradient-to-r from-green-500 to-teal-600 text-white mb-6 shadow-lg transform transition-transform group-hover:rotate-12">
                                        <span className="material-symbols-outlined text-3xl">extension</span>
                                    </span>

                                    <h3 className="text-2xl font-bold mb-2 from-green-600 to-teal-600 bg-clip-text text-transparent">
                                        Customized Plugins
                                    </h3>
                                    <div className="flex items-baseline mb-6">
                                        <span className="text-4xl font-bold">$ 25</span>
                                        <span className="text-gray-500 ml-2"> / Resource</span>
                                    </div>

                                    <ul className="space-y-3 mb-8">
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-green-100 text-green-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">Custom plugin development</p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-green-100 text-green-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">CMS & e-commerce integration</p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-green-100 text-green-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">API development & integration</p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-green-100 text-green-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">Compatibility testing</p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="flex-shrink-0 h-6 w-6 rounded-full bg-green-100 text-green-600 flex items-center justify-center mr-3 mt-0.5">
                                                <span className="material-symbols-outlined text-sm">check</span>
                                            </span>
                                            <p className="text-gray-600">3 months of technical support</p>
                                        </li>
                                    </ul>

                                    <button className="w-full py-3 bg-gradient-to-r from-green-600 to-teal-600 text-white font-medium rounded-lg shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1">
                                        Choose Plan
                                    </button>
                                </div>
                            </div>

                            <div className="mt-20 relative bg-white bg-opacity-80 backdrop-blur-sm rounded-2xl shadow-xl overflow-hidden group">
                                <div className="absolute inset-0 bg-gradient-to-r from-indigo-500/5 via-blue-500/5 to-purple-500/5 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                                <div className="p-8">
                                    <div className="flex flex-col md:flex-row items-center justify-between gap-8">
                                        <div className="md:w-2/3">
                                            <h3 className="text-2xl font-bold from-indigo-600 via-blue-600 to-purple-600 bg-clip-text text-transparent mb-4">
                                                Need a Custom Solution?
                                            </h3>
                                            <p className="text-gray-600 mb-6">
                                                We understand that every business has unique requirements. Contact us
                                                for a personalized solution tailored specifically to your needs.
                                            </p>
                                            <div className="flex flex-wrap gap-4">
                                                <button className="px-6 py-3 bg-gradient-to-r from-indigo-600 to-blue-600 text-white font-medium rounded-lg shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1 hover:from-indigo-700 hover:to-blue-700">
                                                    Contact Sales
                                                </button>
                                                <button className="px-6 py-3 bg-white text-indigo-600 font-medium rounded-lg shadow-md border border-indigo-200 hover:bg-indigo-50 hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1">
                                                    Schedule a Demo
                                                </button>
                                            </div>
                                        </div>
                                        <div className="md:w-1/3 flex justify-center items-center">
                                            <div className="relative overflow-hidden rounded-xl w-full h-48 bg-gradient-to-r from-indigo-100/50 to-blue-100/50 p-6 flex items-center justify-center">
                                                <div className="absolute -top-10 -right-10 w-40 h-40 bg-blue-100 rounded-full opacity-50"></div>
                                                <div className="absolute -bottom-10 -left-10 w-40 h-40 bg-indigo-100 rounded-full opacity-50"></div>
                                                <span className="relative z-10 material-symbols-outlined text-6xl text-indigo-600 animate-pulse">
                                                    support
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-16 bg-white bg-opacity-90 backdrop-blur-sm p-8 rounded-2xl shadow-lg">
                                <h3 className="text-2xl font-bold text-center mb-8 from-indigo-600 to-blue-600 bg-clip-text text-transparent">
                                    Frequently Asked Questions
                                </h3>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 pricingdiv">
                                    <details className="group rounded-lg bg-indigo-50/50 p-4 [&_summary::-webkit-details-marker]:hidden transition-all duration-300 hover:shadow-md">
                                        <summary className="flex cursor-pointer items-center justify-between">
                                            <h4 className="font-medium">Can I upgrade or downgrade my plan?</h4>
                                            <span className="material-symbols-outlined transition duration-300 group-open:rotate-180">
                                                expand_more
                                            </span>
                                        </summary>
                                        <div className="mt-3 text-gray-600">
                                            <p>
                                                Yes, you can easily upgrade or downgrade your plan at any time. Changes
                                                will take effect on your next billing cycle, and pricing will be
                                                adjusted accordingly.
                                            </p>
                                        </div>
                                    </details>
                                </div>
                            </div>
                        </section>
                    </main>
                </div>
            </div>
        </div>
        </div>
        <Footer1/>
        </div>
        </>
    )
}
