import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../CSS/Header.css";
// import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faChevronDown, faBars} from '@fortawesome/free-solid-svg-icons';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import Logo from "../Images/DATADNALogo.png";
import ThemeContext from "./ThemeContext";

const Navbar1=()=>{
    const location = useLocation();
    const navigate = useNavigate();

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);

    // useEffect(() => {
    //   const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
    //     if (user) {
    //       setIsLoggedIn(true);
    //     } else {
    //       setIsLoggedIn(false);
    //     }
    //   });
    //   return unsubscribe;
    // }, []);
const [servicesDropdownVisible, setServicesDropdownVisible] = useState(false);

 const [services, setServices] = useState([
        { name: "Product Development", path: "/service/ProductDevelopment" },
        { name: "UI/UX", path: "/service/UIUX" },
        { name: "Full Stack", path: "/service/FullStack" },
        { name: "AI", path: "/service/AI"},
        { name: "Mobile App Development", path: "/service/MobileAppDevelopment"},
        { name: "Cloud Solution", path: "/service/CloudSolutions"},
        { name: "Digital Transformation", path: "/service/DigitalTransformation"},
        { name: "Process Workflow", path: "/service/ProcessWorkflow"},
        { name: "Business Advisory", path: "/service/BusinessAdvisory"}
    ]);

    const toggleServicesDropdown = () => {
      setServicesDropdownVisible(!servicesDropdownVisible);
  };
  

//   useEffect(() => {
//     const fetchServices = async () => {
//       try {
//         const response = await axios.get("https://us-central1-dev-sportsacademy.cloudfunctions.net/api/getServicesTitle");
//         setServices(response.data);
//         console.log("Fetched Services:", response.data); 
//       } catch (error) {
//         console.error("Error fetching services:", error);
//       }
//     };
//     fetchServices();
//   }, []);

//   const handleLogout = () => {
//     firebase.auth().signOut().then(() => {
//       setIsLoggedIn(false);
//       navigate("/");
//     });
//   };

  const toggleMenu = () => {
    setMenuVisible(!menuVisible); // Toggle the mobile menu visibility
  };

   const [isDarkTheme, setIsDarkTheme] = useState(
      localStorage.getItem("theme") === "dark"
    );

     const servicesDropdownRef = useRef(null);
    
        useEffect(() => {
          const handleClickOutside = (event) => {
            if (
              servicesDropdownRef.current &&
              !servicesDropdownRef.current.contains(event.target)
            ) {
              setServicesDropdownVisible(false);
            }
          };
        
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, []);
    
      // Toggle the theme
      const toggleTheme = () => {
        const newTheme = !isDarkTheme;
        setIsDarkTheme(newTheme);
        localStorage.setItem("theme", newTheme ? "dark" : "light"); // Save theme
      };
    
     useEffect(() => {
        const body = document.body;
        if (isDarkTheme) {
          body.classList.add('dark-theme');
          body.classList.remove('light-theme');
        } else {
          body.classList.add('light-theme');
          body.classList.remove('dark-theme');
        }
      }, [isDarkTheme]);
  
      const [isFixed, setIsFixed] = useState(false);

      useEffect(() => {
        const handleScroll = () => {
            const scrollThreshold = window.innerHeight * 0.15; // 15% of viewport height
            if (window.scrollY > scrollThreshold) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }

            setMenuVisible(false);
            setServicesDropdownVisible(false);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleNavigation = (event, sectionId) => {
      event.preventDefault(); // Prevent default anchor behavior
    
      if (location.pathname !== "/") {
        // Navigate to home page first
        navigate("/", { state: { scrollTo: sectionId } });
      } else {
        // If already on home, scroll immediately
        scrollToSection(sectionId);
      }
    };
    
    const scrollToSection = (sectionId) => {
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 100); // Timeout to ensure navigation completes first
    };
    

    const {isDarkTheme1, toggleTheme1} = useContext(ThemeContext);



    return(
        <nav className={`navbar navbar-expand-lg headermain ftco_navbar ftco-navbar-light ${isFixed ? "fixed" : "relative"}`} id="ftco-navbar">
        <div className="container">
          <a className="navbar-brand" href="/" style={{ color: "white" }}>
             <img src={Logo} alt="logo"/>
            <div className="NavbarTitle1"></div>
           
          </a>
          <button
          className="navbar-toggler"
          type="button"
          onClick={toggleMenu} // Toggle the menu when clicked
          aria-controls="ftco-nav"
          aria-expanded={menuVisible ? 'true' : 'false'}
          aria-label="Toggle navigation"
          style={{color: "black"}}
        >
          <FontAwesomeIcon className="icon" icon={faBars} /> {/* Hamburger icon */}
        </button>
        <div className={`collapse navbar-collapse ${menuVisible ? 'show' : ''}`} id="ftco-nav">
            <ul className="navbar-nav ml-auto">
              <li className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
                <a href="/" className="nav-link text-white">
                  Home
                </a>
              </li>
              <li className={`nav-item ${location.pathname === '/about' ? 'active' : ''}`}>
                <a href="/about" className="nav-link text-white">
                  About
                </a>
              </li>
              <li
                ref={servicesDropdownRef}
                className="nav-item dropdown1"
                onMouseEnter={() => {
                  if (window.innerWidth > 991) setServicesDropdownVisible(true); // hover for desktop
                }}
                onMouseLeave={() => {
                  if (window.innerWidth > 991) setServicesDropdownVisible(false); // hover for desktop
                }}
                onClick={() => {
                  if (window.innerWidth <= 991) setServicesDropdownVisible(!servicesDropdownVisible); // click for mobile
                }}
              >
                <a
                  href="#services"
                  className="nav-link text-white dropdown-toggle"
                  role="button"
                  aria-expanded={servicesDropdownVisible ? "true" : "false"}
                >
                  Services
                </a>
                <ul className={`dropdown-menu1 ${servicesDropdownVisible ? "show" : ""}`}>
                  {services.map((service, index) => (
                    <li key={index}>
                      <a className="dropdown-item" href={service.path}>
                        {service.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
              <li className={`nav-item ${location.pathname === '/contact' ? 'active' : ''}`}>
              <a href="/" onClick={(e) => handleNavigation(e, "coreoffering")} className="nav-link text-white">
                  Core Offering
                </a>
              </li>
              <li className={`nav-item ${location.pathname === '/contact' ? 'active' : ''}`}>
              <a href="/" onClick={(e) => handleNavigation(e, "specializedOfferings")} className="nav-link text-white">
                  Specialized Offerings
                </a>
              </li>
              <li className={`nav-item ${location.pathname === '/contact' ? 'active' : ''}`}>
              <a href="/" onClick={(e) => handleNavigation(e, "customRequirements")} className="nav-link text-white">
                  Resourcing
                </a>
              </li>
              <li className={`nav-item ${location.pathname === '/contact' ? 'active' : ''}`}>
                <a href="/contact" className="nav-link text-white">
                  Contact
                </a>
              </li>
            </ul>
            
            <div className="user-menu">
            <div className="dropdown">
              {/* <FontAwesomeIcon icon={faUser} className="user-icon" /> */}
              {/* <div className="dropdown-content">
               {isLoggedIn ? (
                <>
                  <a href="/workedit">Work</a>
                  <a href="/galleryedit">Gallery</a>
                  <a href="/offeredit">Offer</a>
                  <a href="/serviceedit">Service</a>
                  <a href="/sociallinks">Social Links</a>
                  <a href="/profile">Profile</a>
                  <a href="/seo">SEO</a>
                  <a href="/newperson">Form</a>
                  <a href="/attendanceform">Attendace</a>
                  <a href="/paymentform">Payment</a>
                  <a href="/Attendancedashboard">Attendace Dashboard</a>
                  <a href="/Paymentdashboard">Payment Dashboard</a>
                  <a href="/" onClick={handleLogout}>Logout</a>
                  <a href="/profile">Profile</a>
                  <a href="/linksInsta">Instagram</a>
                  <a href="/linksYoutube">Youtube</a>
                  <a href="/testifilter">TestimonialFilter</a>
                  <a href="/galleryedit">Photo Gallery Edit</a>
                  <a href="/serviceedit">Service Edit</a>
                  <a href="/serviceedit2">Service-2 Edit</a>
                  <a href="/serviceedit3">Service-3 Edit</a>
                  <a href="/" onClick={handleLogout}>Logout</a>
                  </>
               ):(
                  <a href="/auth">Login</a>
               )}
            
              </div> */}
              <button
                    className={`theme-toggle colorbackmain ${isDarkTheme ? 'dark' : 'light'}`}
                    onClick={toggleTheme}
                    >
                    <FontAwesomeIcon icon={isDarkTheme ? faMoon : faSun} />
                </button>
            </div>
          </div>
          </div>
        </div>
      </nav>
    );
}

export default Navbar1;