import React, { useEffect, useState } from "react";
import "../CSS/AboutPage.css";
import Navbar1 from "../Components/Header";
import Footer1 from "../Components/Footer1";
import Loader from "../Components/Loader";
import axios from "axios";

export const About = () => {

    const [story1, setStory1] = useState(null);

    useEffect(() => {
      axios
        .get("https://us-central1-devdatadna.cloudfunctions.net/api/getaboutus1")
        .then((response) => {
          setStory1(response.data);
        })
        .catch((error) => {
          console.error("Error fetching story:", error);
        });
    }, []);

   

    const [story2, setStory2] = useState(null);

    useEffect(() => {
      axios
        .get("https://us-central1-devdatadna.cloudfunctions.net/api/getaboutus2") 
        .then((response) => {
          setStory2(response.data);
        })
        .catch((error) => {
          console.error("Error fetching story:", error);
        });
    }, []);

    const [service, setService] = useState(null);

    useEffect(() => {
        axios
          .get("https://us-central1-devdatadna.cloudfunctions.net/api/getourService")
          .then((response) => {
            setService(response.data);
          })
          .catch((error) => {
            console.error("Error fetching story:", error);
          });
      }, []);

    const [service1, setService1] = useState(null);

    useEffect(() => {
        axios
            .get("https://us-central1-devdatadna.cloudfunctions.net/api/getourService1")
            .then((response) => {
              setService1(response.data);
            })
            .catch((error) => {
              console.error("Error fetching story:", error);
            });
    }, []);

    const [service2, setService2] = useState(null);

    useEffect(() => {
        axios
            .get("https://us-central1-devdatadna.cloudfunctions.net/api/getourService2")
            .then((response) => {
              setService2(response.data);
            })
            .catch((error) => {
              console.error("Error fetching story:", error);
            });
    }, []);


    const [service3, setService3] = useState(null);

    useEffect(() => {
        axios
            .get("https://us-central1-devdatadna.cloudfunctions.net/api/getourService3")
            .then((response) => {
              setService3(response.data);
            })
            .catch((error) => {
              console.error("Error fetching story:", error);
            });
    }, []);


    const [service4, setService4] = useState(null);

    useEffect(() => {
        axios
            .get("https://us-central1-devdatadna.cloudfunctions.net/api/getourService4")
            .then((response) => {
              setService4(response.data);
            })
            .catch((error) => {
              console.error("Error fetching story:", error);
            });
    }, []);
  
    if (!story1) return <p></p>;

    if (!story2) return <p></p>;

    if (!service) return <p></p>;

    if (!service1) return <p></p>;

    if (!service2) return <p></p>;

    if (!service3) return <p></p>;

    if (!service4) return <p></p>;

    return (
        <>
          <Loader/>
        
        <div className="Aboutpage">
        
        <Navbar1/>
        <div className="container">
        <div id="webcrumbs">
            <div className="w-full mx-auto  from-indigo-50 to-blue-50 rounded-xl  overflow-hidden">
                <main className="px-4 sm:px-6 md:px-8 py-6 sm:py-8 md:py-10">
                    <section className="mb-16 relative overflow-hidden">
                        <div className="absolute top-[-50px] right-[-50px] w-[200px] h-[200px] sm:w-[300px] sm:h-[300px]  from-indigo-300/30 to-purple-300/30 rounded-full blur-3xl animate-pulse"></div>
                        <div
                            className="absolute bottom-[-80px] left-[-80px] w-[150px] h-[150px] sm:w-[250px] sm:h-[250px] from-blue-300/30 to-cyan-300/30 rounded-full blur-3xl animate-pulse"
                            style={{animationDelay: "1.5s"}}
                        ></div>

                        <div className="text-center mb-10 sm:mb-12 relative">
                            <div className="inline-block">
                                <span className="text-xs sm:text-sm font-medium py-1 px-2 sm:px-3 rounded-full bg-indigo-100 text-indigo-800 mb-2 sm:mb-3 inline-block">
                                    About Us
                                </span>
                                <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold from-indigo-600 via-blue-500 to-purple-600 bg-clip-text text-transparent pb-2 relative">
                                    Our Digital Journey
                                    <div className="h-1 w-16 sm:w-24 from-indigo-600 to-purple-600 rounded-full absolute bottom-0 left-1/2 transform -translate-x-1/2 animate-pulse"></div>
                                </h2>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 relative z-10">
                            <div className="bg-white bg-opacity-80 backdrop-blur-sm p-6 sm:p-8 rounded-2xl shadow-xl border border-indigo-100 transform transition-all hover:scale-[1.02] hover:shadow-2xl relative overflow-hidden group">
                                <div className="absolute inset-0 bg-gradient-to-br from-indigo-500/5 to-purple-500/5 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                                <div className="absolute top-0 left-0 h-1 w-0 bg-gradient-to-r from-indigo-500 to-purple-500 group-hover:w-full transition-all duration-700"></div>
                                <div className="absolute top-0 right-0 h-0 w-1 bg-gradient-to-b from-purple-500 to-indigo-500 group-hover:h-full transition-all duration-700 delay-100"></div>
                                <div className="absolute bottom-0 right-0 h-1 w-0 bg-gradient-to-l from-indigo-500 to-purple-500 group-hover:w-full transition-all duration-700 delay-200"></div>
                                <div className="absolute bottom-0 left-0 h-0 w-1 bg-gradient-to-t from-purple-500 to-indigo-500 group-hover:h-full transition-all duration-700 delay-300"></div>

                                <span className="flex items-center justify-center h-14 w-14 sm:h-16 sm:w-16 rounded-full bg-gradient-to-r from-indigo-500 to-purple-600 text-white mb-5 sm:mb-6 shadow-lg transform transition-transform group-hover:rotate-12">
                                    <span className="material-symbols-outlined text-2xl sm:text-3xl">laptop_mac</span>
                                </span>

                                <h3 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4 from-indigo-600 to-purple-600 bg-clip-text text-transparent">
                                    {story1.Title}
                                </h3>
                                <p className="text-gray-600 mb-5 sm:mb-6 leading-relaxed text-sm sm:text-base">
                                    {story1.Description}
                                </p>

                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="flex-shrink-0 h-5 w-5 sm:h-6 sm:w-6 rounded-full bg-indigo-100 text-indigo-600 flex items-center justify-center mr-2 sm:mr-3 mt-0.5">
                                            <span className="material-symbols-outlined text-xs sm:text-sm">check</span>
                                        </span>
                                        <p className="text-gray-600 text-sm sm:text-base">
                                            {story1.Point1}
                                        </p>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="flex-shrink-0 h-5 w-5 sm:h-6 sm:w-6 rounded-full bg-indigo-100 text-indigo-600 flex items-center justify-center mr-2 sm:mr-3 mt-0.5">
                                            <span className="material-symbols-outlined text-xs sm:text-sm">check</span>
                                        </span>
                                        <p className="text-gray-600 text-sm sm:text-base">
                                            {story1.Point2}
                                        </p>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="flex-shrink-0 h-5 w-5 sm:h-6 sm:w-6 rounded-full bg-indigo-100 text-indigo-600 flex items-center justify-center mr-2 sm:mr-3 mt-0.5">
                                            <span className="material-symbols-outlined text-xs sm:text-sm">check</span>
                                        </span>
                                        <p className="text-gray-600 text-sm sm:text-base">
                                            {story1.Point3}
                                        </p>
                                    </li>
                                </ul>
                            </div>

                            <div className="bg-white bg-opacity-80 backdrop-blur-sm p-6 sm:p-8 rounded-2xl shadow-xl border border-blue-100 transform transition-all hover:scale-[1.02] hover:shadow-2xl relative overflow-hidden group">
                                <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-cyan-500/5 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                                <div className="absolute top-0 left-0 h-1 w-0 bg-gradient-to-r from-blue-500 to-cyan-500 group-hover:w-full transition-all duration-700"></div>
                                <div className="absolute top-0 right-0 h-0 w-1 bg-gradient-to-b from-cyan-500 to-blue-500 group-hover:h-full transition-all duration-700 delay-100"></div>
                                <div className="absolute bottom-0 right-0 h-1 w-0 bg-gradient-to-l from-blue-500 to-cyan-500 group-hover:w-full transition-all duration-700 delay-200"></div>
                                <div className="absolute bottom-0 left-0 h-0 w-1 bg-gradient-to-t from-cyan-500 to-blue-500 group-hover:h-full transition-all duration-700 delay-300"></div>

                                <span className="flex items-center justify-center h-14 w-14 sm:h-16 sm:w-16 rounded-full bg-gradient-to-r from-blue-500 to-cyan-600 text-white mb-5 sm:mb-6 shadow-lg transform transition-transform group-hover:rotate-12">
                                    <span className="material-symbols-outlined text-2xl sm:text-3xl">lightbulb</span>
                                </span>

                                <h3 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4 from-blue-600 to-cyan-600 bg-clip-text text-transparent">
                                    {story2.Title}
                                </h3>
                                <p className="text-gray-600 mb-5 sm:mb-6 leading-relaxed text-sm sm:text-base">
                                    {story2.Description}
                                </p>

                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="flex-shrink-0 h-5 w-5 sm:h-6 sm:w-6 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center mr-2 sm:mr-3 mt-0.5">
                                            <span className="material-symbols-outlined text-xs sm:text-sm">check</span>
                                        </span>
                                        <p className="text-gray-600 text-sm sm:text-base">
                                            {story2.Point1}
                                        </p>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="flex-shrink-0 h-5 w-5 sm:h-6 sm:w-6 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center mr-2 sm:mr-3 mt-0.5">
                                            <span className="material-symbols-outlined text-xs sm:text-sm">check</span>
                                        </span>
                                        <p className="text-gray-600 text-sm sm:text-base">
                                            {story2.Point2}
                                        </p>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="flex-shrink-0 h-5 w-5 sm:h-6 sm:w-6 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center mr-2 sm:mr-3 mt-0.5">
                                            <span className="material-symbols-outlined text-xs sm:text-sm">check</span>
                                        </span>
                                        <p className="text-gray-600 text-sm sm:text-base">
                                            {story2.Point3}
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="mt-12 sm:mt-16 relative">
                            <div className="text-center mb-8 sm:mb-10">
                                <h3 className="text-2xl sm:text-3xl font-bold from-indigo-600 to-blue-600 bg-clip-text text-transparent mb-3 sm:mb-4">
                                    {service.Title}
                                </h3>
                                <p className=" max-w-2xl mx-auto text-sm sm:text-base">
                                    {service.Description}
                                </p>
                            </div>

                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
                                <div className="bg-gradient-to-br from-indigo-500/[0.08] to-indigo-600/[0.03] p-5 sm:p-6 rounded-xl shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1 relative overflow-hidden group">
                                    <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-indigo-400 to-indigo-600 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left duration-300"></div>
                                    <span className="inline-flex h-10 w-10 sm:h-12 sm:w-12 rounded-lg bg-indigo-100 text-indigo-600 items-center justify-center mb-3 sm:mb-4 group-hover:bg-indigo-200 transition-colors duration-300">
                                        <span className="material-symbols-outlined text-xl sm:text-2xl">devices</span>
                                    </span>
                                    <h3 className="text-base sm:text-lg font-semibold mb-2">{service1.Title}</h3>
                                    <p className=" text-sm sm:text-base">
                                        {service1.Description}
                                    </p>
                                    <div className="mt-3 sm:mt-4 flex items-center text-indigo-600">
                                        <span className="text-2xl sm:text-3xl font-bold aboutspan">{service1.value}</span>
                                        <span className="ml-2 text-xs sm:text-sm aboutspan">
                                            Projects
                                            <br />
                                            Completed
                                        </span>
                                    </div>
                                </div>

                                <div className="bg-gradient-to-br from-blue-500/[0.08] to-blue-600/[0.03] p-5 sm:p-6 rounded-xl shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1 relative overflow-hidden group">
                                    <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-blue-400 to-blue-600 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left duration-300"></div>
                                    <span className="inline-flex h-10 w-10 sm:h-12 sm:w-12 rounded-lg bg-blue-100 text-blue-600 items-center justify-center mb-3 sm:mb-4 group-hover:bg-blue-200 transition-colors duration-300">
                                        <span className="material-symbols-outlined text-xl sm:text-2xl">
                                            smartphone
                                        </span>
                                    </span>
                                    <h3 className="text-base sm:text-lg font-semibold mb-2">{service2.Title}</h3>
                                    <p className="text-sm sm:text-base">
                                        {service2.Description}
                                    </p>
                                    <div className="mt-3 sm:mt-4 flex items-center text-blue-600">
                                        <span className="text-2xl sm:text-3xl font-bold aboutspan">{service2.value}</span>
                                        <span className="ml-2 text-xs sm:text-sm aboutspan">
                                            Apps
                                            <br />
                                            Launched
                                        </span>
                                    </div>
                                </div>

                                <div className="bg-gradient-to-br from-purple-500/[0.08] to-purple-600/[0.03] p-5 sm:p-6 rounded-xl shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1 relative overflow-hidden group">
                                    <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-purple-400 to-purple-600 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left duration-300"></div>
                                    <span className="inline-flex h-10 w-10 sm:h-12 sm:w-12 rounded-lg bg-purple-100 text-purple-600 items-center justify-center mb-3 sm:mb-4 group-hover:bg-purple-200 transition-colors duration-300">
                                        <span className="material-symbols-outlined text-xl sm:text-2xl">cloud</span>
                                    </span>
                                    <h3 className="text-base sm:text-lg font-semibold mb-2">{service3.Title}</h3>
                                    <p className="text-sm sm:text-base">
                                        {service3.Description}
                                    </p>
                                    <div className="mt-3 sm:mt-4 flex items-center text-purple-600">
                                        <span className="text-2xl sm:text-3xl font-bold aboutspan">{service3.value}</span>
                                        <span className="ml-2 text-xs sm:text-sm aboutspan">
                                            Cloud
                                            <br />
                                            Migrations
                                        </span>
                                    </div>
                                </div>

                                <div className="bg-gradient-to-br from-cyan-500/[0.08] to-cyan-600/[0.03] p-5 sm:p-6 rounded-xl shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1 relative overflow-hidden group">
                                    <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-cyan-400 to-cyan-600 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left duration-300"></div>
                                    <span className="inline-flex h-10 w-10 sm:h-12 sm:w-12 rounded-lg bg-cyan-100 text-cyan-600 items-center justify-center mb-3 sm:mb-4 group-hover:bg-cyan-200 transition-colors duration-300">
                                        <span className="material-symbols-outlined text-xl sm:text-2xl">security</span>
                                    </span>
                                    <h3 className="text-base sm:text-lg font-semibold mb-2">{service4.Title}</h3>
                                    <p className="text-sm sm:text-base">
                                        {service4.Description}
                                    </p>
                                    <div className="mt-3 sm:mt-4 flex items-center text-cyan-600">
                                        <span className="text-2xl sm:text-3xl font-bold aboutspan">{service4.value}</span>
                                        <span className="ml-2 text-xs sm:text-sm aboutspan">
                                            Security
                                            <br />
                                            Audits
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-12 sm:mt-16 md:mt-20 relative overflow-hidden">
                            <div className="text-center mb-8 sm:mb-10">
                                <h3 className="text-2xl sm:text-3xl font-bold from-indigo-600 to-blue-600 bg-clip-text text-transparent mb-3 sm:mb-4">
                                    Our Achievements
                                </h3>
                                <p className="max-w-2xl mx-auto text-sm sm:text-base">
                                    Milestones that mark our journey of excellence and innovation
                                </p>
                            </div>

                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
                                <div className="bg-white rounded-xl p-5 sm:p-6  relative overflow-hidden group transition-all duration-300 transform hover:-translate-y-2">
                                    <div className="absolute top-0 right-0 w-16 h-16 sm:w-20 sm:h-20 bg-indigo-100 rounded-bl-full opacity-70 group-hover:w-20 group-hover:h-20 sm:group-hover:w-24 sm:group-hover:h-24 transition-all duration-300"></div>
                                    <div className="relative z-10">
                                        <div className="flex items-center justify-center w-12 h-12 sm:w-16 sm:h-16 bg-gradient-to-br from-indigo-500 to-indigo-600 rounded-lg text-white shadow-lg mb-4 sm:mb-6 group-hover:scale-110 transition-transform duration-300">
                                            <span className="material-symbols-outlined text-2xl sm:text-3xl">
                                                award_star
                                            </span>
                                        </div>
                                        <div className="flex items-baseline mb-2">
                                            <span className="text-3xl sm:text-4xl font-bold text-indigo-600 mr-1 group-hover:scale-110 transition-transform duration-300">
                                                15
                                            </span>
                                            <span className="text-base sm:text-lg  aboutspan">Awards</span>
                                        </div>
                                        <p className="text-gray-600 text-sm sm:text-base">
                                            Industry recognition for our innovative solutions
                                        </p>
                                    </div>
                                </div>

                                <div className="bg-white rounded-xl p-5 sm:p-6 relative overflow-hidden group transition-all duration-300 transform hover:-translate-y-2">
                                    <div className="absolute top-0 right-0 w-16 h-16 sm:w-20 sm:h-20 bg-blue-100 rounded-bl-full opacity-70 group-hover:w-20 group-hover:h-20 sm:group-hover:w-24 sm:group-hover:h-24 transition-all duration-300"></div>
                                    <div className="relative z-10">
                                        <div className="flex items-center justify-center w-12 h-12 sm:w-16 sm:h-16 bg-gradient-to-br from-blue-500 to-blue-600 rounded-lg text-white shadow-lg mb-4 sm:mb-6 group-hover:scale-110 transition-transform duration-300">
                                            <span className="material-symbols-outlined text-2xl sm:text-3xl">
                                                groups
                                            </span>
                                        </div>
                                        <div className="flex items-baseline mb-2">
                                            <span className="text-3xl sm:text-4xl font-bold text-blue-600 mr-1 group-hover:scale-110 transition-transform duration-300">
                                                550
                                            </span>
                                            <span className="text-base sm:text-lg  aboutspan">Clients</span>
                                        </div>
                                        <p className="text-gray-600 text-sm sm:text-base">
                                            Trusted by businesses of all sizes worldwide
                                        </p>
                                    </div>
                                </div>

                                <div className="bg-white rounded-xl p-5 sm:p-6 relative overflow-hidden group transition-all duration-300 transform hover:-translate-y-2">
                                    <div className="absolute top-0 right-0 w-16 h-16 sm:w-20 sm:h-20 bg-purple-100 rounded-bl-full opacity-70 group-hover:w-20 group-hover:h-20 sm:group-hover:w-24 sm:group-hover:h-24 transition-all duration-300"></div>
                                    <div className="relative z-10">
                                        <div className="flex items-center justify-center w-12 h-12 sm:w-16 sm:h-16 bg-gradient-to-br from-purple-500 to-purple-600 rounded-lg text-white shadow-lg mb-4 sm:mb-6 group-hover:scale-110 transition-transform duration-300">
                                            <span className="material-symbols-outlined text-2xl sm:text-3xl">
                                                language
                                            </span>
                                        </div>
                                        <div className="flex items-baseline mb-2">
                                            <span className="text-3xl sm:text-4xl font-bold text-purple-600 mr-1 group-hover:scale-110 transition-transform duration-300">
                                                25
                                            </span>
                                            <span className="text-base sm:text-lg  aboutspan">Countries</span>
                                        </div>
                                        <p className="text-gray-600 text-sm sm:text-base">
                                            Global reach across multiple continents
                                        </p>
                                    </div>
                                </div>

                                <div className="bg-white rounded-xl p-5 sm:p-6 relative overflow-hidden group transition-all duration-300 transform hover:-translate-y-2">
                                    <div className="absolute top-0 right-0 w-16 h-16 sm:w-20 sm:h-20 bg-cyan-100 rounded-bl-full opacity-70 group-hover:w-20 group-hover:h-20 sm:group-hover:w-24 sm:group-hover:h-24 transition-all duration-300"></div>
                                    <div className="relative z-10">
                                        <div className="flex items-center justify-center w-12 h-12 sm:w-16 sm:h-16 bg-gradient-to-br from-cyan-500 to-cyan-600 rounded-lg text-white shadow-lg mb-4 sm:mb-6 group-hover:scale-110 transition-transform duration-300">
                                            <span className="material-symbols-outlined text-2xl sm:text-3xl">code</span>
                                        </div>
                                        <div className="flex items-baseline mb-2">
                                            <span className="text-3xl sm:text-4xl font-bold text-cyan-600 mr-1 group-hover:scale-110 transition-transform duration-300">
                                                1200
                                            </span>
                                            <span className="text-base sm:text-lg  aboutspan">Projects</span>
                                        </div>
                                        <p className="text-gray-600 text-sm sm:text-base">
                                            Successfully delivered projects across industries
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-12 sm:mt-16 relative overflow-hidden">
                            <div className="absolute top-10 right-10 sm:top-20 sm:right-20 w-40 h-40 sm:w-64 sm:h-64 bg-gradient-to-br from-indigo-500/10 to-purple-500/10 rounded-full blur-3xl"></div>
                            <div className="absolute bottom-5 left-5 sm:bottom-10 sm:left-10 w-32 h-32 sm:w-48 sm:h-48 bg-gradient-to-br from-blue-500/10 to-cyan-500/10 rounded-full blur-3xl"></div>

                            <div className="relative bg-white bg-opacity-80 backdrop-blur-sm rounded-2xl shadow-xl overflow-hidden group"></div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
        </div>
        <Footer1/>
        </div>
        </>
    )
}
