import React from "react";
// import "./RefundPolicy.css";
import Navbar1 from "../Components/Header";
import Footer1 from "../Components/Footer1";
import "../CSS/policy.css";
import Loader from "../Components/Loader";

const RefundPolicy = () => {
  return (
    <div className="policypage">
    <Loader/>
    <Navbar1/>
    <div className="container">
      <h1 className="text-center">Refund & Cancellation Policy</h1>
      <p><strong>Effective Date: 13-MAR-2025</strong></p>
      <p>
        At DataDNA (“Company,” “we,” “our,” “us”), we value customer satisfaction and transparency. 
        This Refund & Cancellation Policy outlines our policies regarding the cancellation of services 
        and eligibility for refunds. By using our website www.datadna.in and purchasing our services, 
        you agree to this policy.
      </p>

      <h2>1. Eligibility for Refunds</h2>
      <p>
        We provide a variety of digital services, including SaaS solutions, AI & Data services, cloud 
        solutions, software development, and digital marketing. Refund eligibility depends on the type of service purchased:
      </p>
      <ul>
        <li><strong>SaaS Subscriptions:</strong> No refunds for subscription fees already paid. Users can cancel before the next billing cycle.</li>
        <li><strong>Custom Development Services:</strong> No refunds once work has started. Partial refunds may be considered if canceled before execution.</li>
        <li><strong>Training & Consultation Services:</strong> Refunds available if canceled at least 48 hours before the session.</li>
        <li><strong>Marketing & Advertising Services:</strong> Non-refundable once execution has started.</li>
      </ul>

      <h2>2. Cancellation Policy</h2>
      <ul>
        <li><strong>SaaS Subscriptions:</strong> Can be canceled anytime via account settings, remains active until the end of the billing cycle.</li>
        <li><strong>Custom Projects:</strong> Cancellation requests must be made via email. Cancellation fees may apply.</li>
        <li><strong>Training & Consultation:</strong> Must cancel at least 48 hours before the session for a full refund.</li>
      </ul>

      <h2>3. Non-Refundable Items</h2>
      <ul>
        <li>One-time setup fees</li>
        <li>Domain name purchases</li>
        <li>Third-party software costs</li>
        <li>Completed development work</li>
        <li>Marketing and advertising costs already executed</li>
      </ul>

      <h2>4. Processing Refunds</h2>
      <p>
        If a refund is approved, it will be processed within 7-14 business days through the original payment method. 
        We are not responsible for delays caused by payment gateways or banks.
      </p>

      <h2>5. Chargebacks & Disputes</h2>
      <p>
        Customers are encouraged to contact us before initiating a chargeback. If a chargeback is filed, we reserve the right to:
      </p>
      <ul>
        <li>Suspend the user’s account.</li>
        <li>Refuse future services.</li>
        <li>Take legal action if fraudulent disputes are made.</li>
      </ul>

      <h2>6. Policy Modifications</h2>
      <p>
        DataDNA reserves the right to update this policy at any time. Changes will be posted on our website 
        with an updated Effective Date. Continued use of our services indicates acceptance of the revised policy.
      </p>

      <h2>7. Contact Us</h2>
      <p>
        For refund or cancellation requests, please contact us:
      </p>
      <p><strong>DataDNA</strong><br />
      Website: <a href="https://www.datadna.in" target="_blank" rel="noopener noreferrer">www.datadna.in</a><br />
      Email: <a href="mailto:projects@datadna.in">projects@datadna.in</a></p>
      <p>By purchasing our services, you acknowledge and agree to this Refund & Cancellation Policy.</p>
    </div>
    <Footer1/>
    </div>
  );
};

export default RefundPolicy;
