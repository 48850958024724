import React, { useEffect, useState } from 'react';
import "../CSS/Loader.css";
import IconImage from "../Images/DATADNALogo.png";

const Loader = () => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
      // Simulate a delay to hide the loader after some time (e.g., 3 seconds)
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000);
  
      return () => clearTimeout(timer);
    }, []);
  return (
    <div>
      {loading ? (
        <div id="preloader-active">
      <div className="preloader d-flex align-items-center justify-content-center">
        <div className="preloader-inner position-relative">
          <div className="preloader-circle" />
          <div className="preloader-img pere-text">
            <img src={IconImage} alt="" />
          </div>
        </div>
      </div>
    </div>

      ) : (
        <div className={`content ${loading ? "blurred" : ""}`}>

        </div>
      )}
    </div>
  )
}

export default Loader;