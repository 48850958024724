import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-phone-number-input/style.css"; // Import phone number input styles
import PhoneInput from "react-phone-number-input"; // Import the phone input component
import "../CSS/ContactPage.css";
import Navbar1 from "./Header";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import Navbar from "./Navbar";
import Footer1 from "./Footer1";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const Contact = () => {
  const [name, setName] = useState(""); // State to store name
  const [email, setEmail] = useState(""); // State to store email
  const [phone, setPhone] = useState(""); // State to store phone number
  const [budget, setBudget] = useState(""); // State to store budget
  const [requirement, setRequirement] = useState(""); // State to store requirement
  const [latestId, setLatestId] = useState(0); // To keep track of the latest ID
  const [feedbackMessage, setFeedbackMessage] = useState(""); // To display feedback messages

  // Fetch the latest ID from Firebase when component loads
  useEffect(() => {
    const fetchLatestId = async () => {
      try {
        const snapshot = await firebase.database()
          .ref("Contact Form")
          .orderByChild("Contact_id")
          .limitToLast(1)
          .once("value");

        if (snapshot.exists()) {
          const latestEntry = snapshot.val();
          const latestIdValue = Object.values(latestEntry)[0].Contact_id;
          setLatestId(latestIdValue);
        } else {
          setLatestId(0);
        }
      } catch (error) {
        console.error("Error fetching latest ID:", error);
      }
    };

    fetchLatestId();
  }, []);

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    const db = firebase.database();

    try {
      console.log("Latest ID:", latestId);
      const newId = latestId + 1;
      console.log("New ID:", newId);

      // Push form data to the database
      await db.ref("Contact Form").push({
        Contact_id: newId,
        Contact_name: name,
        Contact_email: email,
        Contact_phone: phone, // Push the phone number here
        Contact_budget: budget, // Push the budget here
        Contact_requirement: requirement, // Push the requirement here
      });

      // Clear form fields and set success message
      setName("");
      setEmail("");
      setPhone("");
      setBudget("");
      setRequirement("");
      setFeedbackMessage("Message sent successfully!"); // Show success message
    } catch (error) {
      console.error("Error storing form data:", error);
      setFeedbackMessage("Failed to send message. Please try again."); // Show error message
    }
  };

  return (
    <>
      <div className="contactpage">
        <Navbar1 />
        <div className="container-fluid min-vh-100 d-flex align-items-center justify-content-center contactarea">
          <div className="row w-100 max-w-7xl g-4 px-3 contactcontent">
            {/* Left Content Section */}
            <div className="col-md-6 d-flex flex-column justify-content-center">
              <h1 className="mb-4 fw-bold">India</h1>
              <p className="mb-2">Plot No 87, 1st Floor, 4th Cross Street,</p>
              <p className="mb-2">Thirumalai Nagar, Perungudi,</p>
              <p className="mb-2">Chennai 600096</p>
              <h1 className="fw-bold mt-4">Sales Team</h1>
              <p className="">+91-9840292498</p>
              <a href="mailto:projects@datadna.in" className="text-primary">projects@datadna.in</a>
            </div>

            {/* Right Form Section */}
            <div className="col-md-6">
              <div className="p-4">
                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <div className="col-md-6 mb-4">
                      <input
                        type="text"
                        id="name"
                        className="form-control"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6">
                      {/* Country Flag with Phone Number Input */}
                      <PhoneInput
                        international
                        defaultCountry="IN"
                        value={phone}
                        onChange={setPhone}
                        className="form-control phone-input"
                        
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6 mb-4">
                      <input
                        type="email"
                        id="email"
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        id="budget"
                        className="form-control"
                        placeholder="Budget"
                        value={budget}
                        onChange={(e) => setBudget(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <textarea
                      id="requirement"
                      className="form-control"
                      rows="4"
                      placeholder="Requirement"
                      value={requirement}
                      onChange={(e) => setRequirement(e.target.value)}
                    ></textarea>
                    <span className="d-block text-end text-muted small">0 / 1800</span>
                  </div>

                  <button type="submit" className="contactbutton">Submit</button>
                </form>

                {/* Feedback Message */}
                {feedbackMessage && <div className="alert alert-info mt-3">{feedbackMessage}</div>}
              </div>
            </div>
          </div>
        </div>
        <Footer1/>
      </div>

    </>
  );
};

export default Contact;