import React from "react";
import Navbar1 from "../Components/Header";
import Footer1 from "../Components/Footer1";
import Loader from "../Components/Loader";

const TermsAndConditions = () => {
  return (
    <div className="policypage">
    <Navbar1/>
    <Loader/>
    <div className="container">
      <h1 className="text-center">Terms and Conditions</h1>
      <p><strong>Effective Date: 13-Mar-2025</strong></p>
      <p>
        Welcome to DataDNA (“Company,” “we,” “our,” “us”). These Terms and
        Conditions (“Terms”) govern your use of our website www.datadna.in
        (“Website”) and our services. By accessing or using our Website and
        services, you agree to these Terms. If you do not agree, please do not
        use our Website.
      </p>
      <h2>1. Definitions</h2>
      <p>
        <strong>“User,” “You,” “Your”</strong> – Refers to any individual or entity accessing our Website or using our services.
      </p>
      <p>
        <strong>“Services”</strong> – Includes IT solutions, AI & data services, SaaS products, digital marketing, cloud solutions, and any other services offered by DataDNA.
      </p>
      <p>
        <strong>“Content”</strong> – Refers to all text, graphics, images, videos, software, and other materials available on the Website.
      </p>
      <h2>2. Eligibility</h2>
      <ul>
        <li>You are at least 18 years old.</li>
        <li>You have the legal authority to agree to these Terms.</li>
        <li>
          If you are using our services on behalf of an organization, you have
          the authority to bind that organization to these Terms.
        </li>
      </ul>
      <h2>3. Use of Our Website and Services</h2>
      <p>You agree to use our Website and services for lawful purposes only and not to:</p>
      <ul>
        <li>Violate any applicable laws or regulations.</li>
        <li>Infringe on our intellectual property rights or those of others.</li>
        <li>Transmit any harmful code (e.g., viruses, malware, or spyware).</li>
        <li>Attempt to gain unauthorized access to our systems or data.</li>
        <li>Use our services for fraudulent, deceptive, or unethical activities.</li>
      </ul>
      <h2>4. Account Registration</h2>
      <p>Some services may require you to create an account. You agree to:</p>
      <ul>
        <li>Provide accurate and complete information.</li>
        <li>Keep your login credentials confidential.</li>
        <li>Notify us immediately if you suspect unauthorized access to your account.</li>
        <li>Be responsible for all activities under your account.</li>
      </ul>
      <p>We reserve the right to suspend or terminate accounts that violate these Terms.</p>
      <h2>5. Payments and Subscriptions</h2>
      <ul>
        <li>Fees must be paid as specified in the payment terms.</li>
        <li>All payments are non-refundable unless stated otherwise.</li>
        <li>We may modify pricing with prior notice.</li>
        <li>If payment fails, we may suspend or terminate your access.</li>
      </ul>
      <h2>6. Intellectual Property Rights</h2>
      <p>All content on our Website, including trademarks, logos, and proprietary software, belongs to DataDNA or its licensors. You may not:</p>
      <ul>
        <li>Copy, reproduce, modify, or distribute our content without permission.</li>
        <li>Use our trademarks without authorization.</li>
        <li>Reverse-engineer or attempt to extract source code from our software.</li>
      </ul>
      <h2>7. Third-Party Services and Links</h2>
      <p>Our Website may contain links to third-party websites and integrations with external services (e.g., Google, Salesforce, Zoho). We are not responsible for their practices, content, or policies. Use them at your own risk.</p>
      <h2>8. Data Privacy</h2>
      <p>Your use of our services is subject to our Privacy Policy. By using our Website, you consent to our data collection and processing practices as outlined in our Privacy Policy.</p>
      <h2>9. Service Availability and Modifications</h2>
      <p>We strive to keep our Website and services available at all times but do not guarantee uninterrupted access. We reserve the right to modify, update, or discontinue any part of our services at any time without prior notice.</p>
      <h2>10. Limitation of Liability</h2>
      <p>To the fullest extent permitted by law:</p>
      <ul>
        <li>DataDNA is not liable for any indirect, incidental, or consequential damages.</li>
        <li>We provide our services “as is” without warranties of any kind.</li>
        <li>Our total liability for any claim shall not exceed the amount you paid for our services in the past 12 months.</li>
      </ul>
      <h2>11. Indemnification</h2>
      <p>You agree to indemnify and hold DataDNA, its affiliates, and employees harmless from any claims, damages, or expenses arising from:</p>
      <ul>
        <li>Your violation of these Terms.</li>
        <li>Your misuse of our services.</li>
        <li>Any legal disputes resulting from your activities.</li>
      </ul>
      <h2>12. Termination</h2>
      <p>We may terminate or suspend your access if:</p>
      <ul>
        <li>You violate these Terms.</li>
        <li>We discontinue our services.</li>
        <li>There are legal or security concerns requiring immediate action.</li>
      </ul>
      <p>Upon termination, you must cease using our Website and delete any downloaded content.</p>
      <h2>13. Governing Law & Dispute Resolution</h2>
      <p>These Terms are governed by the laws of [Your Country/State]. Any disputes shall be resolved through arbitration or in the courts of [Your Jurisdiction].</p>
      <h2>14. Changes to These Terms</h2>
      <p>We may update these Terms from time to time. The latest version will be posted on this page with the updated Effective Date.</p>
      <h2>15. Contact Us</h2>
      <p>If you have any questions regarding these Terms, please contact us:</p>
      <p><strong>DataDNA</strong></p>
      <p>Website: <a href="https://www.datadna.in">www.datadna.in</a></p>
      <p>Email: <a href="mailto:projects@datadna.in">projects@datadna.in</a></p>
      <p>By using our Website and services, you acknowledge that you have read and understood these Terms and agree to be bound by them.</p>
    </div>
    <Footer1/>
    </div>
  );
};

export default TermsAndConditions;
