import React from "react"
import Navbar1 from "../../Components/Header"
import Footer1 from "../../Components/Footer1"
import Loader from "../../Components/Loader"
import Flutter from "../../Images/Service/Flutter.png";
import Swift from "../../Images/Service/Swift1.png";
import Kotlin from "../../Images/Service/Kotlin.png";
import PWA from "../../Images/Service/PWA1.png";
import Firebase from "../../Images/Service/Firebase.png";

export const MobileAppDevelopmentPage = () => {
    return (
        <>
        <Loader/>
        <div className="Servicepage">
        <Navbar1/>
        <div className="container">
        <div id="webcrumbs2">
            <div className="w-full max-w-[1280px] bg-gradient-to-br font-sans mx-auto">
                <header className="py-12 md:py-20 px-6 md:px-10 text-center relative overflow-hidden">
                    <div className="absolute top-0 left-0 w-full h-full bg-blue-500 opacity-5 transform -skew-y-6"></div>
                    <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 text-slate-800 relative z-10">
                        Mobile App Development Service
                    </h2>
                    <p className="text-justify text-lg md:text-xl max-w-4xl mx-auto relative z-10">
                    At DATADNA, we specialize in crafting world-class mobile applications tailored to your business goals. Whether you're launching a startup MVP or expanding your enterprise ecosystem, we deliver seamless user experiences across iOS, Android, and hybrid platforms.
                    </p>
                    <div className="flex flex-col md:flex-row justify-center mt-8 md:mt-10 space-y-2 md:space-y-0">
                        <button className="bg-blue-600 text-white px-6 md:px-8 py-3 rounded-lg md:mr-4 font-medium transition-all duration-300 hover:bg-blue-700 hover:shadow-lg transform hover:-translate-y-1">
                            Get Started
                        </button>
                      
                    </div>
                    {/* Next: "Add an animated scroll indicator arrow" */}
                </header>

                <section className="py-12 md:py-16 px-6 md:px-10">
                    <h2 className="text-2xl md:text-3xl font-bold text-center mb-10 md:mb-16 text-slate-800">
                    Empower Your Business with Cutting-Edge Mobile Solutions
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
                        <div className="bg-white p-6 md:p-8 rounded-xl shadow-md transition-all duration-300 hover:shadow-xl hover:-translate-y-2">
                            <div className="bg-blue-100 w-14 h-14 md:w-16 md:h-16 rounded-full flex items-center justify-center mb-6">
                                <span className="material-symbols-outlined text-blue-600 text-2xl md:text-3xl">
                                    smart_toy
                                </span>
                            </div>
                            <h3 className="text-lg md:text-xl font-semibold mb-3 md:mb-4 text-slate-800">
                            Increased Customer Engagement & Retention
                            </h3>
                            <p className="text-gray-600 text-justify">
                            Mobile apps provide a direct and personalized channel for interacting with customers through push notifications, in-app messages, and personalized offers.

                            </p>
                        </div>

                        <div className="bg-white p-6 md:p-8 rounded-xl shadow-md transition-all duration-300 hover:shadow-xl hover:-translate-y-2">
                            <div className="bg-indigo-100 w-14 h-14 md:w-16 md:h-16 rounded-full flex items-center justify-center mb-6">
                                <span className="material-symbols-outlined text-indigo-600 text-2xl md:text-3xl">
                                    trending_up
                                </span>
                            </div>
                            <h3 className="text-lg md:text-xl font-semibold mb-3 md:mb-4 text-slate-800">
                            Higher Revenue Potential Through Monetization
                            </h3>
                            <p className="text-gray-600 text-justify">
                            Mobile apps open up multiple revenue streams such as in-app purchases, subscriptions, advertisements, and premium content.

                            </p>
                        </div>

                        <div className="bg-white p-6 md:p-8 rounded-xl shadow-md transition-all duration-300 hover:shadow-xl hover:-translate-y-2">
                            <div className="bg-cyan-100 w-14 h-14 md:w-16 md:h-16 rounded-full flex items-center justify-center mb-6">
                                <span className="material-symbols-outlined text-cyan-600 text-2xl md:text-3xl">
                                    security
                                </span>
                            </div>
                            <h3 className="text-lg md:text-xl font-semibold mb-3 md:mb-4 text-slate-800">
                            Better Brand Visibility & Customer Loyalty
                            </h3>
                            <p className="text-gray-600 text-justify">
                            Having a mobile app enhances brand visibility by keeping the business in front of customers 24/7 on their smartphones.

                            </p>
                        </div>
                    </div>
                    {/* Next: "Add a 'View all features' button" */}
                </section>

                <section className="py-12 md:py-16 px-6 md:px-10 bg-gradient-to-r from-slate-800 to-blue-900 text-white">
                    <h2 className="text-2xl md:text-3xl font-bold text-center mb-10 md:mb-16">Our Mobile App Development Services</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
                        <div className="relative">
                            <div className="absolute -left-3 -top-3 md:-left-4 md:-top-4 w-12 h-12 md:w-16 md:h-16 bg-blue-500 opacity-20 rounded-lg"></div>
                            <div className="relative z-10 border border-blue-400 p-6 md:p-8 rounded-lg bg-blue-900 bg-opacity-30 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-50 hover:shadow-lg">
                                <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 flex items-center">
                                    <span className="material-symbols-outlined mr-2 md:mr-3 text-blue-300">
                                        insights
                                    </span>
                                    iOS App Development
                                </h3>
                                <p className="text-justify mb-3">
                                Innovative & Scalable iOS Solutions
                                </p>
                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-blue-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Native Swift & Objective-C Development</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-blue-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>iPhone & iPad App Development</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-blue-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>App Store Deployment & Optimization</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-blue-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Seamless UI/UX for Apple Ecosystem</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="relative">
                            <div className="absolute -left-3 -top-3 md:-left-4 md:-top-4 w-12 h-12 md:w-16 md:h-16 bg-indigo-500 opacity-20 rounded-lg"></div>
                            <div className="relative z-10 border border-indigo-400 p-6 md:p-8 rounded-lg bg-indigo-900 bg-opacity-30 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-50 hover:shadow-lg">
                                <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 flex items-center">
                                    <span className="material-symbols-outlined mr-2 md:mr-3 text-indigo-300">
                                        auto_awesome
                                    </span>
                                    Android App Development
                                </h3>
                                <p className="text-justify mb-3">
                                Engaging & Robust Android Experiences
                                </p>
                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-indigo-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Kotlin & Java Development</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-indigo-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Android Smartphone, Tablet & Wearable Apps</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-indigo-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Google Play Store Optimization</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-indigo-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>AI-Driven Personalization & Performance Tuning</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="relative">
                            <div className="absolute -left-3 -top-3 md:-left-4 md:-top-4 w-12 h-12 md:w-16 md:h-16 bg-cyan-500 opacity-20 rounded-lg"></div>
                            <div className="relative z-10 border border-cyan-400 p-6 md:p-8 rounded-lg bg-cyan-900 bg-opacity-30 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-50 hover:shadow-lg">
                                <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 flex items-center">
                                    <span className="material-symbols-outlined mr-2 md:mr-3 text-cyan-300">
                                        person_search
                                    </span>
                                    Cross-Platform App Development
                                </h3>
                                <p className="text-justify mb-3">
                                One Codebase, Multiple Platforms! 
                                </p>
                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-cyan-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>React Native & Flutter Development</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-cyan-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Cost-Effective & Time-Efficient Solutions</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-cyan-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Native-Like Performance & UI/UX</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-cyan-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Deployment on Both iOS & Android</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="relative">
                            <div className="absolute -left-3 -top-3 md:-left-4 md:-top-4 w-12 h-12 md:w-16 md:h-16 bg-purple-500 opacity-20 rounded-lg"></div>
                            <div className="relative z-10 border border-purple-400 p-6 md:p-8 rounded-lg bg-purple-900 bg-opacity-30 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-50 hover:shadow-lg">
                                <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 flex items-center">
                                    <span className="material-symbols-outlined mr-2 md:mr-3 text-purple-300">
                                        trending_up
                                    </span>
                                    UI/UX Design & App Prototyping
                                </h3>
                                <p className="text-justify mb-3">
                                User-Centric & Intuitive Designs
                                </p>
                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Interactive Wireframing & Prototyping</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Engaging Visual & Motion Design</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Seamless User Experience (UX) Optimization</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Conversion-Driven Design Strategies</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Next: "Add a case study section with real-world examples" */}
                </section>

                <section className="py-12 md:py-16 px-6 md:px-10">
                    <h2 className="text-2xl md:text-3xl font-bold text-center mb-10 md:mb-16 text-slate-800">
                    How Mobile Apps Impact Businesses
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 mb-10 md:mb-16">
                        <div className="bg-gradient-to-br from-blue-500 to-indigo-600 p-6 md:p-8 rounded-xl text-white shadow-lg transform transition-transform duration-300 hover:scale-105">
                            <h3 className="text-4xl md:text-5xl font-bold mb-2 text-center">85%</h3>
                            <p className="text-blue-100 text-base md:text-lg">
                            of users prefer mobile apps over websites 
                            </p>
                        </div>

                        <div className="bg-gradient-to-br from-indigo-500 to-purple-600 p-6 md:p-8 rounded-xl text-white shadow-lg transform transition-transform duration-300 hover:scale-105">
                            <h3 className="text-4xl md:text-5xl font-bold mb-2 text-center">200%</h3>
                            <p className="text-indigo-100 text-base md:text-lg">
                            Businesses witness a increase in customer retention with mobile apps
                            </p>
                        </div>

                        <div className="bg-gradient-to-br from-cyan-500 to-blue-600 p-6 md:p-8 rounded-xl text-white shadow-lg transform transition-transform duration-300 hover:scale-105">
                            <h3 className="text-4xl md:text-5xl font-bold mb-2 text-center">3X</h3>
                            <p className="text-cyan-100 text-base md:text-lg">
                            higher conversion rates on mobile apps vs. mobile websites
                            </p>
                        </div>
                    </div>

                    {/* <div className="bg-white p-6 md:p-10 rounded-2xl shadow-xl">
                        <h3 className="text-xl md:text-2xl font-semibold mb-6 md:mb-8 text-slate-800 text-center">
                            AI Adoption by Industry
                        </h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-10">
                            <div className="space-y-6">
                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Healthcare</span>
                                        <span className="font-medium">76%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[76%] transition-all duration-1000"></div>
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Financial Services</span>
                                        <span className="font-medium">82%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[82%] transition-all duration-1000"></div>
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Retail</span>
                                        <span className="font-medium">68%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[68%] transition-all duration-1000"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="space-y-6">
                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Manufacturing</span>
                                        <span className="font-medium">71%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[71%] transition-all duration-1000"></div>
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Transportation</span>
                                        <span className="font-medium">64%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[64%] transition-all duration-1000"></div>
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Energy & Utilities</span>
                                        <span className="font-medium">59%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[59%] transition-all duration-1000"></div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>*/}
                    
                </section>
                <section className="py-12 md:py-16 px-4 md:px-6">
                    <div className="text-center mb-8 md:mb-12">
                        <h2 className="text-2xl md:text-3xl font-bold mb-3 md:mb-4">Mobile App Technologies We Use</h2>
                        <p className="max-w-4xl mx-auto text-justify">
                        Mobile App Development enhances customer engagement by providing instant access, personalized experiences, and seamless communication. Businesses leveraging mobile apps report 20-30% higher conversion rates and significantly improved customer lifetime value (CLV). Mobile apps also offer a direct revenue channel through in-app purchases, subscriptions, and advertisements.
                        </p>
                    </div>

                    <div className="grid grid-cols-1  md:grid-cols-3 gap-6  md:gap-8">
                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-blue-50 flex flex-col items-center ">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                                <i className="fa-brands fa-react text-5xl text-blue-500"></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2"> React Native</h3>
                            <p className="text-gray-600 text-sm text-justify">
                            Open-source framework developed by Facebook that enables developers to build cross-platform mobile apps
                            </p>
                        </div>

                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-green-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                                <i className="fa-brands text-5xl text-green-600"><img src={Flutter} alt="Flutter"/></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2">Flutter</h3>
                            <p className="text-gray-600 text-sm text-justify">
                            Powerful framework that uses the Dart programming language to create high-performance, cross-platform apps.
                            </p>
                        </div>

                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-green-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                            <i className="fa-brands text-5xl text-green-600"><img src={Swift} alt="Swift"/></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2">Swift</h3>
                            <p className="text-gray-600 text-sm text-justify">Swift is Apple’s official programming language for developing iOS applications.</p>
                        </div>

                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-red-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                                <i className="fa-brands text-5xl text-red-600"><img src={Kotlin} alt="Kotlin"/></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2">Kotlin</h3>
                            <p className="text-gray-600 text-sm text-justify">
                            Kotlin is a modern, statically-typed programming language officially supported by Google for Android development.
                            </p>
                        </div>

                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-blue-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                                <i className="fa-brands text-5xl text-orange-500"><img src={PWA} alt="Progressive Web Apps"/></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2">Progressive Web Apps (PWAs)</h3>
                            <p className="text-gray-600 text-sm text-justify">
                            PWAs combine the best of web and mobile apps, offering offline capabilities, fast loading times, and push notifications.
                            </p>
                        </div>

                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-purple-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                            <i className="fa-brands text-5xl text-orange-500"><img src={Firebase} alt="Firebase"/></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2">Firebase</h3>
                            <p className="text-gray-600 text-sm text-justify">
                            Powerful backend-as-a-service (BaaS) platform that offers real-time databases, cloud storage, authentication, and analytics.
                            </p>
                        </div>
                    </div>
                </section>


                <section className="py-12 md:py-16 px-6 md:px-10">
                    <h2 className="text-2xl md:text-3xl font-bold text-center mb-6 md:mb-10 text-slate-800">
                    Let’s Launch Your New App
                    </h2>
                    <p className="text-justify text-base md:text-lg max-w-4xl mx-auto mb-8 md:mb-10">
                    Mobile App Development transforms businesses by providing 24/7 customer engagement, personalized experiences, and increased accessibility. With mobile apps, businesses witness a 30-40% boost in customer retention and 25-30% higher revenue through in-app monetization.
                    </p>
                    <div className="flex justify-center">
                        <button className="bg-blue-600 text-white px-6 md:px-8 py-3 md:py-4 rounded-lg font-medium text-base md:text-lg transition-all duration-300 hover:bg-blue-700 hover:shadow-xl transform hover:-translate-y-1">
                            Schedule a Consultation
                        </button>
                    </div>
                    {/* Next: "Add a newsletter signup form with email validation" */}
                </section>
            </div>
        </div>
        </div>
        <Footer1/>
        </div>
        </>
    )
}