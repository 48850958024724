import React from 'react';
import { FaWhatsapp, FaPhone, FaArrowUp } from 'react-icons/fa';
import '../CSS/FloatingIcons.css';

const FloatingIcons = ({ isFooter }) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div className={`floating-icons ${isFooter ? 'footer-icons' : ''}`}>
     <a
        href="#"
        className="floating-iconup top"
        onClick={scrollToTop}
        style={isFooter ? { color: 'black', margin: '0 10px' } : {}}
      >
        <FaArrowUp />
      </a>
      <a
        href="https://api.whatsapp.com/send/?phone=919840292498&text&type=phone_number&app_absent=0"
        className="floating-icon whatsapp"
        target="_blank"
        rel="noopener noreferrer"
        style={isFooter ? { color: 'black', margin: '0 10px' } : {}}
      >
        <FaWhatsapp />
      </a>
      <a
        href="tel:+91 9840292498"
        className="floating-icon phone"
        style={{ 
        transform: 'rotate(90deg)', // Adjust the angle as needed
        ...(isFooter ? { color: 'black', margin: '0 10px' } : {}) 
      }}
      >
        <FaPhone />
      </a>
     
    </div>
  );
};

export default FloatingIcons;
