import React from "react"
import Navbar1 from "../../Components/Header"
import Footer1 from "../../Components/Footer1"
import Loader from "../../Components/Loader"
import Cloud from "../../Images/Service/cloud-platform.png";
import Conten from "../../Images/Service/containerization.png";
import DevOps from "../../Images/Service/devops.png";
import Monitoring from "../../Images/Service/statistics.png";
import Security from "../../Images/Service/cyber-security.png";
import database from "../../Images/Service/database.png";

export const CloudSolutionsPage = () => {
    return (
        <>
        <Loader/>
        <div className="Servicepage">
        <Navbar1/>
        <div className="container">
        <div id="webcrumbs2">
            <div className="w-full max-w-[1280px] bg-gradient-to-br font-sans mx-auto">
                <header className="py-12 md:py-20 px-6 md:px-10 text-center relative overflow-hidden">
                    <div className="absolute top-0 left-0 w-full h-full bg-blue-500 opacity-5 transform -skew-y-6"></div>
                    <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 text-slate-800 relative z-10">
                        Cloud Solutions Service
                    </h2>
                    <p className="text-lg md:text-xl max-w-4xl mx-auto relative z-10 text-justify">
                    In today’s digital world, cloud technology is the backbone of innovation, scalability, and security. At DATADNA, we provide end-to-end cloud solutions, enabling businesses to migrate, optimize, and manage their applications on secure cloud platforms.
                    </p>
                    <div className="flex flex-col md:flex-row justify-center mt-8 md:mt-10 space-y-2 md:space-y-0">
                        <button className="bg-blue-600 text-white px-6 md:px-8 py-3 rounded-lg md:mr-4 font-medium transition-all duration-300 hover:bg-blue-700 hover:shadow-lg transform hover:-translate-y-1">
                            Get Started
                        </button>
                       
                    </div>
                    {/* Next: "Add an animated scroll indicator arrow" */}
                </header>

                <section className="py-12 md:py-16 px-6 md:px-10">
                    <h2 className="text-2xl md:text-3xl font-bold text-center mb-10 md:mb-16 text-slate-800">
                    Why Choose Our Cloud Solutions?
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
                        <div className="bg-white p-6 md:p-8 rounded-xl shadow-md transition-all duration-300 hover:shadow-xl hover:-translate-y-2">
                            <div className="bg-blue-100 w-14 h-14 md:w-16 md:h-16 rounded-full flex items-center justify-center mb-6">
                                <span className="material-symbols-outlined text-blue-600 text-2xl md:text-3xl">
                                    smart_toy
                                </span>
                            </div>
                            <h3 className="text-lg md:text-xl font-semibold mb-3 md:mb-4 text-slate-800">
                            End-to-End Cloud Services
                            </h3>
                            <p className="text-gray-600 text-justify">
                            From migration to security and DevOps, we handle everything.
                            </p>
                        </div>

                        <div className="bg-white p-6 md:p-8 rounded-xl shadow-md transition-all duration-300 hover:shadow-xl hover:-translate-y-2">
                            <div className="bg-indigo-100 w-14 h-14 md:w-16 md:h-16 rounded-full flex items-center justify-center mb-6">
                                <span className="material-symbols-outlined text-indigo-600 text-2xl md:text-3xl">
                                    trending_up
                                </span>
                            </div>
                            <h3 className="text-lg md:text-xl font-semibold mb-3 md:mb-4 text-slate-800">
                                Security & Compliance
                            </h3>
                            <p className="text-gray-600 text-justify">
                            Advanced data protection, encryption, and regulatory compliance.
                            </p>
                        </div>

                        <div className="bg-white p-6 md:p-8 rounded-xl shadow-md transition-all duration-300 hover:shadow-xl hover:-translate-y-2">
                            <div className="bg-cyan-100 w-14 h-14 md:w-16 md:h-16 rounded-full flex items-center justify-center mb-6">
                                <span className="material-symbols-outlined text-cyan-600 text-2xl md:text-3xl">
                                    security
                                </span>
                            </div>
                            <h3 className="text-lg md:text-xl font-semibold mb-3 md:mb-4 text-slate-800">
                                DevOps & Automation
                            </h3>
                            <p className="text-gray-600 text-justify">
                            CI/CD pipelines, Kubernetes, Docker, faster deployment cycles.
                            </p>
                        </div>
                    </div>
                    {/* Next: "Add a 'View all features' button" */}
                </section>

                <section className="py-12 md:py-16 px-6 md:px-10 bg-gradient-to-r from-slate-800 to-blue-900 text-white">
                    <h2 className="text-2xl md:text-3xl font-bold text-center mb-10 md:mb-16">Our Cloud Solutions & Offerings</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
                        <div className="relative">
                            <div className="absolute -left-3 -top-3 md:-left-4 md:-top-4 w-12 h-12 md:w-16 md:h-16 bg-blue-500 opacity-20 rounded-lg"></div>
                            <div className="relative z-10 border border-blue-400 p-6 md:p-8 rounded-lg bg-blue-900 bg-opacity-30 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-50 hover:shadow-lg">
                                <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 flex items-center">
                                    <span className="material-symbols-outlined mr-2 md:mr-3 text-blue-300">
                                        insights
                                    </span>
                                    Cloud Migration & Modernization
                                </h3>
                                <h6 className="mb-4">Seamless transition from on-premise to cloud with minimal downtime & maximum efficiency.</h6>
                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-blue-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Assessment & cloud readiness analysis</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-blue-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Lift-and-shift & re-architecting solutions</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-blue-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Application & database migration</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-blue-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Multi-cloud & hybrid cloud strategy</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="relative">
                            <div className="absolute -left-3 -top-3 md:-left-4 md:-top-4 w-12 h-12 md:w-16 md:h-16 bg-indigo-500 opacity-20 rounded-lg"></div>
                            <div className="relative z-10 border border-indigo-400 p-6 md:p-8 rounded-lg bg-indigo-900 bg-opacity-30 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-50 hover:shadow-lg">
                                <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 flex items-center">
                                    <span className="material-symbols-outlined mr-2 md:mr-3 text-indigo-300">
                                        auto_awesome
                                    </span>
                                    Cloud-Native Development
                                </h3>
                                <h6 className="mb-4">We build cloud-first applications designed for scalability, high performance, and cost efficiency.</h6>
                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-indigo-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Microservices architecture & serverless computing</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-indigo-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>API-driven cloud applications</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-indigo-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>AI/ML-powered cloud solutions</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-indigo-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Cloud-native DevOps & CI/CD integration</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="relative">
                            <div className="absolute -left-3 -top-3 md:-left-4 md:-top-4 w-12 h-12 md:w-16 md:h-16 bg-cyan-500 opacity-20 rounded-lg"></div>
                            <div className="relative z-10 border border-cyan-400 p-6 md:p-8 rounded-lg bg-cyan-900 bg-opacity-30 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-50 hover:shadow-lg">
                                <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 flex items-center">
                                    <span className="material-symbols-outlined mr-2 md:mr-3 text-cyan-300">
                                        person_search
                                    </span>
                                    Cloud Security & Compliance
                                </h3>
                                <h6 className="mb-4">Comprehensive security frameworks to protect your data, applications, and infrastructure.</h6>
                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-cyan-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Identity & access management (IAM)</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-cyan-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Data encryption & security audits</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-cyan-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Disaster recovery & business continuity planning</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-cyan-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Compliance with GDPR, HIPAA, SOC 2, ISO 27001</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="relative">
                            <div className="absolute -left-3 -top-3 md:-left-4 md:-top-4 w-12 h-12 md:w-16 md:h-16 bg-purple-500 opacity-20 rounded-lg"></div>
                            <div className="relative z-10 border border-purple-400 p-6 md:p-8 rounded-lg bg-purple-900 bg-opacity-30 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-50 hover:shadow-lg">
                                <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 flex items-center">
                                    <span className="material-symbols-outlined mr-2 md:mr-3 text-purple-300">
                                        trending_up
                                    </span>
                                    DevOps & Cloud Automation
                                </h3>
                                <h6 className="mb-4">Enhancing agility and efficiency with cloud automation, CI/CD pipelines, and containerization.</h6>
                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Kubernetes & Docker container orchestration</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Automated deployments & infrastructure as code (IaC)</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Continuous integration & delivery (CI/CD) pipelines</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span> Monitoring & performance optimization</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Next: "Add a case study section with real-world examples" */}
                </section>

                <section className="py-12 md:py-16 px-6 md:px-10">
                    <h2 className="text-2xl md:text-3xl font-bold text-center mb-10 md:mb-16 text-slate-800">
                    Cloud Metrics & Industry Statistics
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 mb-10 md:mb-16">
                        <div className="bg-gradient-to-br from-blue-500 to-indigo-600 p-6 md:p-8 rounded-xl text-white shadow-lg transform transition-transform duration-300 hover:scale-105">
                            <h3 className="text-4xl md:text-5xl font-bold mb-2 text-center">90%</h3>
                            <p className="text-blue-100 text-base md:text-lg text-justify">
                            of enterprises are already on the cloud for agility & scalability.
                            </p>
                        </div>

                        <div className="bg-gradient-to-br from-indigo-500 to-purple-600 p-6 md:p-8 rounded-xl text-white shadow-lg transform transition-transform duration-300 hover:scale-105">
                            <h3 className="text-4xl md:text-5xl font-bold mb-2 text-center">30-50%</h3>
                            <p className="text-indigo-100 text-base md:text-lg text-justify">
                            Cost Reduction when we implement Cloud adoption
                            </p>
                        </div>

                        <div className="bg-gradient-to-br from-cyan-500 to-blue-600 p-6 md:p-8 rounded-xl text-white shadow-lg transform transition-transform duration-300 hover:scale-105">
                            <h3 className="text-4xl md:text-5xl font-bold mb-2 text-center">75%</h3>
                            <p className="text-cyan-100 text-base md:text-lg text-justify">
                            Increase in speed when we implement Serverless computing & microservices
                            </p>
                        </div>
                    </div>

                    {/* <div className="bg-white p-6 md:p-10 rounded-2xl shadow-xl">
                        <h3 className="text-xl md:text-2xl font-semibold mb-6 md:mb-8 text-slate-800 text-center">
                            AI Adoption by Industry
                        </h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-10">
                            <div className="space-y-6">
                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Healthcare</span>
                                        <span className="font-medium">76%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[76%] transition-all duration-1000"></div>
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Financial Services</span>
                                        <span className="font-medium">82%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[82%] transition-all duration-1000"></div>
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Retail</span>
                                        <span className="font-medium">68%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[68%] transition-all duration-1000"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="space-y-6">
                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Manufacturing</span>
                                        <span className="font-medium">71%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[71%] transition-all duration-1000"></div>
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Transportation</span>
                                        <span className="font-medium">64%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[64%] transition-all duration-1000"></div>
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Energy & Utilities</span>
                                        <span className="font-medium">59%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[59%] transition-all duration-1000"></div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>*/}
                    
                </section>
                <section className="py-12 md:py-16 px-4 md:px-6">
                    <div className="text-center mb-8 md:mb-12">
                        <h2 className="text-2xl md:text-3xl font-bold mb-3 md:mb-4">Tools & Technologies</h2>
                        <p className="max-w-4xl mx-auto">
                        We work with the world’s leading cloud providers and automation tools to build robust, secure, and scalable solutions.
                        </p>
                    </div>

                    <div className="grid grid-cols-1  md:grid-cols-3  gap-6 md:gap-8">
                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-blue-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                                <i className="fa-brands text-5xl text-blue-500"><img src={Cloud} alt="Cloud Platforms"/></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2">Cloud Platforms</h3>
                            <p className="text-gray-600 text-sm text-justify">
                                AWS, Microsoft Azure, Google Cloud
                            </p>
                        </div>

                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-green-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                                <i className="fa-brands text-5xl text-green-600"><img src={Conten} alt="Containerization"/></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2">Containerization & Orchestration</h3>
                            <p className="text-gray-600 text-sm text-justify">
                                Docker, Kubernetes, OpenShift
                            </p>
                        </div>

                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-green-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                            <i className="fa-brands text-5xl text-green-600"><img src={DevOps} alt="Devops"/></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2">DevOps & CI/CD</h3>
                            <p className="text-gray-600 text-sm text-justify">Jenkins, GitHub Actions, GitLab CI/CD, Terraform</p>
                        </div>

                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-red-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                                <i className="fa-brands  text-5xl text-red-600"><img src={Security} alt="Security"/></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2">Security & Compliance</h3>
                            <p className="text-gray-600 text-sm text-justify">
                            AWS IAM, Azure Security Center, Google Cloud Security Command Center
                            </p>
                        </div>

                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-blue-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                                <i className="fa-brands text-5xl text-orange-500"><img src={Monitoring} alt="Monitoring"/></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2">Monitoring & Performance</h3>
                            <p className="text-gray-600 text-sm text-justify">
                            Prometheus, Grafana, AWS CloudWatch, Azure Monitor
                            </p>
                        </div>

                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-purple-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                            <i className="fa-brands text-5xl text-orange-500"><img src={database} alt="Database"/></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2">Storage & Databases</h3>
                            <p className="text-gray-600 text-sm text-justify">
                            Amazon S3, Google Cloud Storage, Azure Blob, PostgreSQL, Firebase
                            </p>
                        </div>
                    </div>
                </section>


                <section className="py-12 md:py-16 px-6 md:px-10">
                    <h2 className="text-2xl md:text-3xl font-bold text-center mb-6 md:mb-10 text-slate-800">
                    Scale Your Business with our Cloud Expertise
                    </h2>
                    <p className="text-justify text-base md:text-lg max-w-4xl mx-auto mb-8 md:mb-10">
                    Accelerate your digital transformation with our secure, scalable, and cost-effective cloud solutions. Whether you need cloud migration, cloud-native development, DevOps, or security compliance, we ensure seamless integration, high availability, and optimized performance. Empower your business with AWS, Azure, Google Cloud, and advanced automation for unmatched agility and efficiency.
                    </p>
                    <div className="flex justify-center">
                        <button className="bg-blue-600 text-white px-6 md:px-8 py-3 md:py-4 rounded-lg font-medium text-base md:text-lg transition-all duration-300 hover:bg-blue-700 hover:shadow-xl transform hover:-translate-y-1">
                            Schedule a Consultation
                        </button>
                    </div>
                    {/* Next: "Add a newsletter signup form with email validation" */}
                </section>
            </div>
        </div>
        </div>
        <Footer1/>
        </div>
        </>
    )
}