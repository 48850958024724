import React, { useState, useEffect } from 'react';
import axios from "axios";
import "../CSS/SpecializedOfferings.css";
import zoho from "../Images/zohor.gif";
import salesforce from "../Images/Salesforcer.gif";
import servicenow from "../Images/Servicenowr.gif";
import Shopify from "../Images/Shopifyr.gif";



const SpecializedOfferings=()=>{

    const [specializedOfferings, setSpecializedOfferings] = useState(null);

    useEffect(() => {
     axios
       .get("https://us-central1-devdatadna.cloudfunctions.net/api/getSpecializedOffering")
       .then((response) => {
         setSpecializedOfferings(response.data);
       })
       .catch((error) => {
         console.error("Error fetching story:", error);
       });
   }, []);
 
   if (!specializedOfferings) return <p></p>;

    const customServices = [
        { icon: <img src={zoho} alt={specializedOfferings.Title1} />, title: specializedOfferings.Title1, description: specializedOfferings.Description1 },
        { icon: <img src={salesforce} alt={specializedOfferings.Title2} />, title: specializedOfferings.Title2, description: specializedOfferings.Description2 },
        { icon: <img src={servicenow} alt={specializedOfferings.Title3} />, title: specializedOfferings.Title3, description: specializedOfferings.Description3 },
        { icon: <img src={Shopify} alt={specializedOfferings.Title4} />, title: specializedOfferings.Title4, description: specializedOfferings.Description4 },
      ];


    return(
        <>
        <div className="specialized-offering p-4">
        <div className="container p-4">
        <h1 className="p-4 text-center mb-4">Specialized Offerings</h1>
            <div className="custom-services-container">
            {customServices.map((service, index) => (
            <div className="custom-service-card" key={index}>
                <div className="icon">{service.icon}</div>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
                {/* <span className="learn-more-text">Learn More</span> */}
            </div>
            ))}
        </div>
      </div>
      </div>
        </>
    );
}

export default SpecializedOfferings;