import React, { useState, useEffect } from 'react';
import axios from "axios";
import { FaCode, FaPaintBrush, FaCogs, FaBrain, FaChartLine, FaMobileAlt, FaCloud, FaSync, FaTasks } from "react-icons/fa";
import "../CSS/CoreOfferinghome.css";
import productDevelopment from "../Images/ProductDevelopment.gif";
import UIUX from "../Images/UIUX.gif";
import FullStack from "../Images/FullStack.gif";
import AI from "../Images/AI-R.gif";
import mobile from "../Images/Mbiler.gif";
import cloud from "../Images/cloudr.gif";
import Digital from "../Images/DigitalTransformation.gif";
import workflow from "../Images/ProcessWorkflow.gif";




const CoreOfferinghome=()=>{

    const [coreOffering, setCoreOffering] = useState(null);

    useEffect(() => {
     axios
       .get("https://us-central1-devdatadna.cloudfunctions.net/api/getCoreOffering")
       .then((response) => {
         setCoreOffering(response.data);
       })
       .catch((error) => {
         console.error("Error fetching story:", error);
       });
   }, []);
 
   if (!coreOffering) return <p></p>;

   const services = [
    { icon: <img src={productDevelopment} alt= {coreOffering.title1} />, title: coreOffering.title1 , description: coreOffering.description1, href: `/service/ProductDevelopment`},
    { icon: <img src={UIUX} alt= {coreOffering.title2} />, title: coreOffering.title2, description: coreOffering.description2, href: `/service/UIUX` },
    { icon: <img src={FullStack} alt= {coreOffering.title3} />, title: coreOffering.title3, description: coreOffering.description3, href: `/service/FullStack` },
    { icon: <img src={AI} alt= {coreOffering.title4} />, title: coreOffering.title4, description: coreOffering.description4, href: `/service/AI` },
    { icon: <img src={mobile} alt= {coreOffering.title5} />, title: coreOffering.title5, description: coreOffering.description5, href: `/service/MobileAppDevelopment` },
    { icon: <img src={cloud} alt={coreOffering.title6} />, title: coreOffering.title6, description: coreOffering.description6, href: `/service/CloudSolutions` },
    { icon: <img src={Digital} alt={coreOffering.title7} />, title: coreOffering.title7, description: coreOffering.description7, href: `/service/DigitalTransformation` },
    { icon: <img src={workflow} alt={coreOffering.title8} />, title: coreOffering.title8, description: coreOffering.description8, href: `/service/ProcessWorkflow` }
  ];

    return(
        <div className="core-offering-home p-4">
        <div className="container  p-4">
        <h1 className="p-4 text-center">Core Offerings</h1>
        <div className="coreofferinghome">
       
        {services.map((service, index) => (
            <a
              key={index}
              href={service.href || "#"}
              className="service-card"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="service-icon">{service.icon}</div>
              <h3 className="service-title">{service.title}</h3>
              <p className="service-description" style={{ textAlign: "justify" }}>
                {service.description}
              </p>
            </a>
          ))}

        </div>
        </div>
        </div>
    );
}

export default CoreOfferinghome;