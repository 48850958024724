import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import Footer1 from '../Components/Footer1';
import Contact from '../Components/Contact';
import Navbar from '../Components/Navbar';
import Loader from '../Components/Loader';

const ContactPage=()=>{
    return(
        <>
        <Loader/>
                <Contact/>
           
        </>
    );
}

export default ContactPage;