import React from "react"
import Navbar1 from "../../Components/Header"
import Footer1 from "../../Components/Footer1"
import Loader from "../../Components/Loader"
import RPA from "../../Images/Service/rpa.png";
import Workato from "../../Images/Service/learning.png";
import Nocode from "../../Images/Service/no-results.png";
import Lowcode from "../../Images/Service/code.png";
import Zapier from "../../Images/Service/automation.png";
import Power from "../../Images/Service/blockchain.png";

export const ProcessWorkflowPage = () => {
    return (
        <>
        <Loader/>
        <div className="Servicepage">
        <Navbar1/>
        <div className="container">
        <div id="webcrumbs2">
            <div className="w-full max-w-[1280px] bg-gradient-to-br font-sans mx-auto">
                <header className="py-12 md:py-20 px-6 md:px-10 text-center relative overflow-hidden">
                    <div className="absolute top-0 left-0 w-full h-full bg-blue-500 opacity-5 transform -skew-y-6"></div>
                    <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 text-slate-800 relative z-10">
                        Process & Workflow Service
                    </h2>
                    <p className="text-lg md:text-xl max-w-3xl mx-auto relative z-10">
                    Our Process & Workflow Automation services help you eliminate bottlenecks, improve productivity, and drive seamless business operations.
                    </p>
                    <div className="flex flex-col md:flex-row justify-center mt-8 md:mt-10 space-y-2 md:space-y-0">
                        <button className="bg-blue-600 text-white px-6 md:px-8 py-3 rounded-lg md:mr-4 font-medium transition-all duration-300 hover:bg-blue-700 hover:shadow-lg transform hover:-translate-y-1">
                            Get Started
                        </button>
                       
                    </div>
                    {/* Next: "Add an animated scroll indicator arrow" */}
                </header>

                <section className="py-12 md:py-16 px-6 md:px-10">
                    <h2 className="text-2xl md:text-3xl font-bold text-center mb-10 md:mb-16 text-slate-800">
                        Why Choose Our Process & Workflow
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
                        <div className="bg-white p-6 md:p-8 rounded-xl shadow-md transition-all duration-300 hover:shadow-xl hover:-translate-y-2">
                            <div className="bg-blue-100 w-14 h-14 md:w-16 md:h-16 rounded-full flex items-center justify-center mb-6">
                                <span className="material-symbols-outlined text-blue-600 text-2xl md:text-3xl">
                                    smart_toy
                                </span>
                            </div>
                            <h3 className="text-lg md:text-xl font-semibold mb-3 md:mb-4 text-slate-800">
                                AI-Driven & Intelligent Automation
                            </h3>
                            <p className="text-gray-600 text-justify">
                            We incorporate AI and ML to optimize workflows and predict process inefficiencies.
                            </p>
                        </div>

                        <div className="bg-white p-6 md:p-8 rounded-xl shadow-md transition-all duration-300 hover:shadow-xl hover:-translate-y-2">
                            <div className="bg-indigo-100 w-14 h-14 md:w-16 md:h-16 rounded-full flex items-center justify-center mb-6">
                                <span className="material-symbols-outlined text-indigo-600 text-2xl md:text-3xl">
                                    trending_up
                                </span>
                            </div>
                            <h3 className="text-lg md:text-xl font-semibold mb-3 md:mb-4 text-slate-800">
                            Customizable & Scalable Solutions
                            </h3>
                            <p className="text-gray-600 text-justify">
                            We tailor automation to your business needs, ensuring long-term scalability.

                            </p>
                        </div>

                        <div className="bg-white p-6 md:p-8 rounded-xl shadow-md transition-all duration-300 hover:shadow-xl hover:-translate-y-2">
                            <div className="bg-cyan-100 w-14 h-14 md:w-16 md:h-16 rounded-full flex items-center justify-center mb-6">
                                <span className="material-symbols-outlined text-cyan-600 text-2xl md:text-3xl">
                                    security
                                </span>
                            </div>
                            <h3 className="text-lg md:text-xl font-semibold mb-3 md:mb-4 text-slate-800">
                            Seamless System Integration
                            </h3>
                            <p className="text-gray-600 text-justify">
                            We integrate automation with your CRM, ERP, HRMS, and other business tools for uninterrupted workflows.
                            </p>
                        </div>
                    </div>
                    {/* Next: "Add a 'View all features' button" */}
                </section>

                <section className="py-12 md:py-16 px-6 md:px-10 bg-gradient-to-r from-slate-800 to-blue-900 text-white">
                    <h2 className="text-2xl md:text-3xl font-bold text-center mb-10 md:mb-16">Our Process & Workflow Automation Offerings</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
                        <div className="relative">
                            <div className="absolute -left-3 -top-3 md:-left-4 md:-top-4 w-12 h-12 md:w-16 md:h-16 bg-blue-500 opacity-20 rounded-lg"></div>
                            <div className="relative z-10 border border-blue-400 p-6 md:p-8 rounded-lg bg-blue-900 bg-opacity-30 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-50 hover:shadow-lg">
                                <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 flex items-center">
                                    <span className="material-symbols-outlined mr-2 md:mr-3 text-blue-300">
                                        insights
                                    </span>
                                    Business Process Automation (BPA)
                                </h3>
                                <p className="mb-4">We automate repetitive tasks, approvals, and workflows, reducing manual intervention and errors.</p>
                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-blue-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Automate approvals, invoices, and documentation</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-blue-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Reduce processing time and human errors</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-blue-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Improve collaboration between teams & departments</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-blue-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>AI-powered task management & scheduling</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="relative">
                            <div className="absolute -left-3 -top-3 md:-left-4 md:-top-4 w-12 h-12 md:w-16 md:h-16 bg-indigo-500 opacity-20 rounded-lg"></div>
                            <div className="relative z-10 border border-indigo-400 p-6 md:p-8 rounded-lg bg-indigo-900 bg-opacity-30 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-50 hover:shadow-lg">
                                <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 flex items-center">
                                    <span className="material-symbols-outlined mr-2 md:mr-3 text-indigo-300">
                                        auto_awesome
                                    </span>
                                    Robotic Process Automation (RPA)
                                </h3>
                                <p className="mb-4">Use AI-driven bots to handle high-volume tasks, ensuring speed, accuracy, and efficiency.</p>
                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-indigo-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Automate rule-based and repetitive processes</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-indigo-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Reduce manual workload and optimize productivity</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-indigo-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Enhance accuracy with AI-driven bots</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-indigo-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Seamless integration with existing systems</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="relative">
                            <div className="absolute -left-3 -top-3 md:-left-4 md:-top-4 w-12 h-12 md:w-16 md:h-16 bg-cyan-500 opacity-20 rounded-lg"></div>
                            <div className="relative z-10 border border-cyan-400 p-6 md:p-8 rounded-lg bg-cyan-900 bg-opacity-30 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-50 hover:shadow-lg">
                                <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 flex items-center">
                                    <span className="material-symbols-outlined mr-2 md:mr-3 text-cyan-300">
                                        person_search
                                    </span>
                                    Workflow Orchestration & Integration
                                </h3>
                                <p className="mb-4">Connect multiple applications, tools, and platforms to ensure smooth data flow and business process continuity.</p>
                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-cyan-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Automate cross-departmental workflows</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-cyan-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Seamless integration with ERP, CRM, HRMS, Accounting Software</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-cyan-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>API-based workflow automation</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-cyan-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Real-time data synchronization across platforms</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="relative">
                            <div className="absolute -left-3 -top-3 md:-left-4 md:-top-4 w-12 h-12 md:w-16 md:h-16 bg-purple-500 opacity-20 rounded-lg"></div>
                            <div className="relative z-10 border border-purple-400 p-6 md:p-8 rounded-lg bg-purple-900 bg-opacity-30 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-50 hover:shadow-lg">
                                <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 flex items-center">
                                    <span className="material-symbols-outlined mr-2 md:mr-3 text-purple-300">
                                        trending_up
                                    </span>
                                    AI-Driven Process Optimization & Analytics
                                </h3>
                                <p className="mb-4">Leverage AI-powered insights to optimize processes, predict workflow bottlenecks, and improve decision-making.</p>
                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Real-time process monitoring & insights</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Predictive analytics for performance improvements</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>AI-based decision-making automation</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Advanced dashboard & reporting automation</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Next: "Add a case study section with real-world examples" */}
                </section>

                <section className="py-12 md:py-16 px-6 md:px-10">
                    <h2 className="text-2xl md:text-3xl font-bold text-center mb-10 md:mb-16 text-slate-800">
                    Process & Workflow Automation Impact
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 mb-10 md:mb-16">
                        <div className="bg-gradient-to-br from-blue-500 to-indigo-600 p-6 md:p-8 rounded-xl text-white shadow-lg transform transition-transform duration-300 hover:scale-105">
                            <h3 className="text-4xl md:text-5xl font-bold mb-2 text-center">45%</h3>
                            <p className="text-justify text-blue-100 text-base md:text-lg">
                            of business activities can be automated, increasing efficiency and reducing costs.
                            </p>
                        </div>

                        <div className="bg-gradient-to-br from-indigo-500 to-purple-600 p-6 md:p-8 rounded-xl text-white shadow-lg transform transition-transform duration-300 hover:scale-105">
                            <h3 className="text-4xl md:text-5xl font-bold mb-2 text-center">40-60%</h3>
                            <p className="text-justify text-indigo-100 text-base md:text-lg">
                            reduction in manual errors while using Automation
                            </p>
                        </div>

                        <div className="bg-gradient-to-br from-cyan-500 to-blue-600 p-6 md:p-8 rounded-xl text-white shadow-lg transform transition-transform duration-300 hover:scale-105">
                            <h3 className="text-4xl md:text-5xl font-bold mb-2 text-center">95%</h3>
                            <p className="text-justify text-cyan-100 text-base md:text-lg">
                            of business leaders say process automation improves productivity and performance.
                            </p>
                        </div>
                    </div>

                    {/* <div className="bg-white p-6 md:p-10 rounded-2xl shadow-xl">
                        <h3 className="text-xl md:text-2xl font-semibold mb-6 md:mb-8 text-slate-800 text-center">
                            AI Adoption by Industry
                        </h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-10">
                            <div className="space-y-6">
                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Healthcare</span>
                                        <span className="font-medium">76%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[76%] transition-all duration-1000"></div>
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Financial Services</span>
                                        <span className="font-medium">82%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[82%] transition-all duration-1000"></div>
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Retail</span>
                                        <span className="font-medium">68%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[68%] transition-all duration-1000"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="space-y-6">
                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Manufacturing</span>
                                        <span className="font-medium">71%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[71%] transition-all duration-1000"></div>
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Transportation</span>
                                        <span className="font-medium">64%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[64%] transition-all duration-1000"></div>
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Energy & Utilities</span>
                                        <span className="font-medium">59%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[59%] transition-all duration-1000"></div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>*/}
                    
                </section>
                <section className="py-12 md:py-16 px-4 md:px-6">
                    <div className="text-center mb-8 md:mb-12">
                        <h2 className="text-2xl md:text-3xl font-bold mb-3 md:mb-4">Tools & Technologies</h2>
                        <p className="max-w-4xl mx-auto">
                        We leverage cutting-edge tools and technologies to streamline automation and ensure seamless workflows
                        </p>
                    </div>

                    <div className="grid grid-cols-1  md:grid-cols-3  gap-6 md:gap-8">
                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-blue-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                                <i className="fa-brands text-5xl text-blue-500"><img src={RPA} alt="RPA"/></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2">UiPath</h3>
                            <p className="text-gray-600 text-sm text-center">
                            Leading RPA tool for intelligent automation.
                            </p>
                        </div>

                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-green-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                            <i className="fa-brands text-5xl text-blue-500"><img src={Zapier} alt="Zapier"/></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2">Zapier</h3>
                            <p className="text-gray-600 text-sm text-center">
                            Automates workflows across multiple applications.
                            </p>
                        </div>

                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-green-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                            <i className="fa-brands text-5xl text-blue-500"><img src={Power} alt="Power"/></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2">Power Automate (Microsoft)</h3>
                            <p className="text-gray-600 text-sm text-center">Connects and automates business processes in Microsoft ecosystem.</p>
                        </div>

                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-red-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                            <i className="fa-brands text-5xl text-blue-500"><img src={Lowcode} alt="Low code"/></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2">Nintex</h3>
                            <p className="text-gray-600 text-sm text-center">
                            Low-code automation for business process optimization.
                            </p>
                        </div>

                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-blue-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                            <i className="fa-brands text-5xl text-blue-500"><img src={Workato} alt="Workato"/></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2">Workato</h3>
                            <p className="text-gray-600 text-sm text-center">
                            AI-driven automation and workflow integration.
                            </p>
                        </div>

                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-purple-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                            <i className="fa-brands text-5xl text-blue-500"><img src={Nocode} alt="Nocode"/></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2">Kissflow</h3>
                            <p className="text-gray-600 text-sm text-center">
                            No-code workflow automation platform for enterprises.
                            </p>
                        </div>
                    </div>
                </section>


                <section className="py-12 md:py-16 px-6 md:px-10">
                    <h2 className="text-2xl md:text-3xl font-bold text-center mb-6 md:mb-10 text-slate-800">
                    Let’s Setup Your Business Workflow
                    </h2>
                    <p className="text-center text-base md:text-lg max-w-4xl mx-auto mb-8 md:mb-10 ">
                    Adopting Process & Workflow Automation can transform your business by boosting efficiency, reducing costs, and improving employee productivity.
                    </p>
                    <div className="flex justify-center">
                        <button className="bg-blue-600 text-white px-6 md:px-8 py-3 md:py-4 rounded-lg font-medium text-base md:text-lg transition-all duration-300 hover:bg-blue-700 hover:shadow-xl transform hover:-translate-y-1">
                            Schedule a Consultation
                        </button>
                    </div>
                    {/* Next: "Add a newsletter signup form with email validation" */}
                </section>
            </div>
        </div>
        </div>
        <Footer1/>
        </div>
        </>
    )
}