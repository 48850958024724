import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../CSS/Navbar.css";
// import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faChevronDown, faBars} from '@fortawesome/free-solid-svg-icons';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import Logo from "../Images/DATADNALogo.png";
import ThemeContext from "./ThemeContext";

const Navbar=()=>{
    const location = useLocation();
    const navigate = useNavigate();

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    
    
    const [servicesDropdownVisible, setServicesDropdownVisible] = useState(false);

    const [services, setServices] = useState([
        { name: "Product Development", path: "/service/ProductDevelopment" },
        { name: "UI/UX", path: "/service/UIUX" },
        { name: "Full Stack", path: "/service/FullStack" },
        { name: "AI", path: "/service/AI"},
        { name: "Mobile App Development", path: "/service/MobileAppDevelopment"},
        { name: "Cloud Solution", path: "/service/CloudSolutions"},
        { name: "Digital Transformation", path: "/service/DigitalTransformation"},
        { name: "Process Workflow", path: "/service/ProcessWorkflow"},
        { name: "Business Advisory", path: "/service/BusinessAdvisory"}
    ]);

    const servicesDropdownRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          servicesDropdownRef.current &&
          !servicesDropdownRef.current.contains(event.target)
        ) {
          setServicesDropdownVisible(false);
        }
      };
    
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
    
  const toggleMenu = () => {
    setMenuVisible(!menuVisible); // Toggle the mobile menu visibility
  };

  const toggleServicesDropdown = () => {
    setServicesDropdownVisible(!servicesDropdownVisible);
};


  const [isDarkTheme, setIsDarkTheme] = useState(
    localStorage.getItem("theme") === "dark" 
  );
    
      // Toggle the theme
      const toggleTheme = () => {
        const newTheme = !isDarkTheme;
        setIsDarkTheme(newTheme);
        localStorage.setItem("theme", newTheme ? "dark" : "light"); // Save theme
      };
    
     useEffect(() => {
        const body = document.body;
        if (isDarkTheme) {
          body.classList.add('dark-theme');
          body.classList.remove('light-theme');
        } else {
          body.classList.add('light-theme');
          body.classList.remove('dark-theme');
        }
      }, [isDarkTheme]);
  
      const [isFixed, setIsFixed] = useState(false);

      useEffect(() => {
        const handleScroll = () => {
            const scrollThreshold = window.innerHeight * 0.15; // 15% of viewport height
            if (window.scrollY > scrollThreshold) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }

            setMenuVisible(false);
            setServicesDropdownVisible(false);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleScroll = (event, id) => {
      event.preventDefault();
      const element = document.getElementById(id);
      if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
  };
  
  const handleScroll1 = (event, id) => {
    event.preventDefault();
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "end" });
    }
};

const {isDarkTheme1, toggleTheme1} = useContext(ThemeContext);

    return(
        <nav className={`navbar navbar-expand-lg ftco_navbar ftco-navbar-light ${isFixed ? "fixed" : "relative"}`} id="ftco-navbar">
        <div className="container">
          <a className="navbar-brand" href="/" style={{ color: "white" }}>
            <img src={Logo} alt="logo"/>
            <div className="NavbarTitle"></div>

          </a>
          <button
          className="navbar-toggler"
          type="button"
          onClick={toggleMenu} // Toggle the menu when clicked
          aria-controls="ftco-nav"
          aria-expanded={menuVisible ? 'true' : 'false'}
          aria-label="Toggle navigation"
          style={{color: "black"}}
        >
          <FontAwesomeIcon icon={faBars} className="icon"/> {/* Hamburger icon */}
        </button>
        <div className={`collapse navbar-collapse ${menuVisible ? 'show' : ''}`} id="ftco-nav">
            <ul className="navbar-nav ml-auto">
              <li className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
                <a href="/" className="nav-link text-white">
                  Home
                </a>
              </li>
              <li className={`nav-item ${location.pathname === '/about' ? 'active' : ''}`}>
                <a href="/about" className="nav-link text-white">
                  About
                </a>
              </li>
              <li
                ref={servicesDropdownRef}
                className="nav-item dropdown1"
                onMouseEnter={() => {
                  if (window.innerWidth > 991) setServicesDropdownVisible(true); // hover for desktop
                }}
                onMouseLeave={() => {
                  if (window.innerWidth > 991) setServicesDropdownVisible(false); // hover for desktop
                }}
                onClick={() => {
                  if (window.innerWidth <= 991) setServicesDropdownVisible(!servicesDropdownVisible); // click for mobile
                }}
              >
                <a
                  href="#services"
                  className="nav-link text-white dropdown-toggle"
                  role="button"
                  aria-expanded={servicesDropdownVisible ? "true" : "false"}
                >
                  Services
                </a>
                <ul className={`dropdown-menu1 ${servicesDropdownVisible ? "show" : ""}`}>
                  {services.map((service, index) => (
                    <li key={index}>
                      <a className="dropdown-item" href={service.path}>
                        {service.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>


              <li className={`nav-item ${location.pathname === '/contact' ? 'active' : ''}`}>
                <a href="#coreoffering"  onClick={(e) => handleScroll1(e, "coreoffering")} className="nav-link text-white">
                  Core Offering
                </a>
              </li>
              <li className={`nav-item ${location.pathname === '/contact' ? 'active' : ''}`}>
                <a href="#specializedOfferings"  onClick={(e) => handleScroll(e, "specializedOfferings")} className="nav-link text-white">
                  Specialized Offerings
                </a>
              </li>
              <li className={`nav-item ${location.pathname === '/contact' ? 'active' : ''}`}>
                <a href="#customRequirements" onClick={(e) => handleScroll(e, "customRequirements")} className="nav-link text-white">
                  Resourcing
                </a>
              </li>
              <li className={`nav-item ${location.pathname === '/contact' ? 'active' : ''}`}>
                <a href="/contact" className="nav-link text-white">
                  Contact
                </a>
              </li>
            </ul>
            
            <div className="user-menu">
            <div className="dropdown">
             
              <button
                    className={`htheme-toggle ${isDarkTheme ? 'dark' : 'light'}`}
                    onClick={toggleTheme}
                    >
                    <FontAwesomeIcon icon={isDarkTheme ? faMoon : faSun} />
                </button>
            </div>
          </div>
          </div>
        </div>
      </nav>
    );
}

export default Navbar;