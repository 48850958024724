import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";
import Cogni from "../Images/project/Cogniglob.png";
import JVS from "../Images/project/JVSBodyWorks.png";
import Sourc from "../Images/project/Sourc.png";
import Balaji from "../Images/project/Balaji.png";
import Janushree from "../Images/project/Janushree.png";

const Clientlogos1 = () => {
  const logos = [
    { src: Cogni, alt: "Cogniglob" },
    { src: JVS, alt: "JVS Body Works" },
    { src: Sourc, alt: "Sourc" },
    { src: Balaji, alt: "Balaji Sports Academy" },
    { src: Janushree, alt: "Janushree Ladies Salon" }
  ];

  return (
    <div className="p-4 clientlogos">
      <Swiper
        dir="rtl"
        spaceBetween={window.innerWidth < 768 ? 10 : 20} // Adjust spacing for mobile
        slidesPerView={window.innerWidth < 768 ? 2 : 4} // Show fewer slides on mobile
        loop={true}
        autoplay={{
          delay: 400,
          disableOnInteraction: true,
          pauseOnMouseEnter: false,
          reverseDirection: false,
          stopOnLastSlide: false,
          speed: 5000,
        }}
        allowTouchMove={true} // Enable swipe on mobile
        modules={[Autoplay]}
      >
        {[...logos, ...logos].map((logo, index) => (
          <SwiperSlide key={index}>
            <div
              className="divclientlogs"
              style={{
                width: "120px",
                height: "90px",
                margin: window.innerWidth < 768 ? "5px" : "0px 10px", // Space between images on mobile
              }}
            >
              <img
                src={logo.src}
                alt={logo.alt}
                style={{
                  width: "100px",
                  height: "80px",
                  padding: window.innerWidth < 768 ? "5px" : "0px", // Adjust padding for mobile
                }}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Clientlogos1;
