import React from "react";
import Navbar1 from "../Components/Header";
import Footer1 from "../Components/Footer1";
import Loader from "../Components/Loader";

const PrivacyPolicy = () => {
  return (
    <div className="policypage">
    <Loader/>
      <Navbar1 />
      <div className="container">
        <h1 className="text-center">Privacy Policy</h1>
        <p><strong>Effective Date: 13-Mar-2025</strong></p>
        <p>
          Welcome to <span>DataDNA</span> (“we,” “our,” “us”). We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and protect your information when you visit our website{" "}
          <a href="https://www.datadna.in" target="_blank" rel="noopener noreferrer">
            www.datadna.in
          </a> (“Website”) and use our services.
        </p>

        <p>
          By accessing our Website or using our services, you agree to the terms outlined in this Privacy Policy. If you do not agree, please refrain from using our Website.
        </p>

        <div>
          <h2 className="text-left">1. Information We Collect</h2>
          <p>We collect different types of information to provide and improve our services:</p>

          <h5 className="text-left">1.1 Personal Information</h5>
          <p>We collect personal information that you provide to us voluntarily, including but not limited to:</p>
          <ul>
            <li>Full Name</li>
            <li>Email Address</li>
            <li>Contact Number</li>
            <li>Company Name (if applicable)</li>
            <li>Billing and Payment Information (for paid services)</li>
          </ul>

          <h5 className="text-left">1.2 Automatically Collected Information</h5>
          <p>When you visit our Website, we may automatically collect:</p>
          <ul>
            <li>IP Address</li>
            <li>Browser Type and Version</li>
            <li>Device Information</li>
            <li>Cookies and Tracking Data</li>
            <li>Usage Data (such as pages visited, time spent, clicks, etc.)</li>
          </ul>

          <h5 className="text-left">1.3 Information from Third-Party Services</h5>
          <p>If you use third-party integrations (such as Google, Facebook, or API services like Salesforce, Zoho, etc.), we may receive your information as permitted by these platforms.</p>
        </div>

        <div>
          <h2 className="text-left">2. How We Use Your Information</h2>
          <ul>
            <li>Providing, maintaining, and improving our services</li>
            <li>Personalizing your experience</li>
            <li>Processing transactions and sending invoices</li>
            <li>Sending service-related announcements, updates, and promotional materials</li>
            <li>Enhancing security and fraud prevention</li>
            <li>Complying with legal obligations</li>
          </ul>
        </div>

        <div>
          <h2 className="text-left">3. How We Share Your Information</h2>
          <h5 className="text-left">3.1 With Service Providers</h5>
          <p>We may share your information with third-party service providers who help us operate our business, such as payment processors, analytics providers, and cloud storage services.</p>

          <h5 className="text-left">3.2 For Legal Compliance</h5>
          <p>We may disclose your information if required by law or in response to valid legal requests by public authorities.</p>

          <h5 className="text-left">3.3 Business Transfers</h5>
          <p>If our company is involved in a merger, acquisition, or sale of assets, your data may be transferred as part of the transaction.</p>
        </div>

        <div>
          <h2 className="text-left">4. Cookies and Tracking Technologies</h2>
          <p>We use cookies and similar tracking technologies to enhance user experience and gather analytical data. You can manage cookie settings in your browser.</p>
          <ul>
            <li>Essential Cookies – Required for website functionality</li>
            <li>Analytics Cookies – Help us understand usage patterns</li>
            <li>Advertising Cookies – Used for marketing and retargeting</li>
          </ul>
        </div>

        <div>
          <h2 className="text-left">5. Data Security</h2>
          <p>We implement strict security measures to protect your data, including encryption, firewalls, and secure servers. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.</p>
        </div>

        <div>
          <h2 className="text-left">6. Your Rights and Choices</h2>
          <p>You have certain rights regarding your data:</p>
          <ul>
            <li><strong>Access & Correction:</strong> You can request access to your data and update incorrect information.</li>
            <li><strong>Data Deletion:</strong> You may request the deletion of your data unless we are legally required to retain it.</li>
            <li><strong>Opt-Out of Marketing:</strong> You can opt out of promotional emails by following the unsubscribe link.</li>
          </ul>
          <p>To exercise these rights, contact us at <strong>projects@datadna.in</strong>.</p>
        </div>

        <div>
          <h2 className="text-left">7. Third-Party Links</h2>
          <p>Our Website may contain links to third-party websites. We are not responsible for their privacy practices, so we encourage you to review their policies before sharing any personal data.</p>
        </div>

        <div>
          <h2 className="text-left">8. Children’s Privacy</h2>
          <p>Our services are not intended for individuals under the age of 18. We do not knowingly collect personal data from children.</p>
        </div>

        <div>
          <h2 className="text-left">9. Changes to This Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time. The latest version will be posted on this page with the updated Effective Date. Your continued use of the Website signifies acceptance of these changes.</p>
        </div>

        <div>
          <h2 className="text-left">10. Contact Us</h2>
          <p>If you have any questions regarding this Privacy Policy, please contact us at:</p>
          <p><strong>DataDNA</strong></p>
          <p>Website: <a href="https://www.datadna.in" target="_blank" rel="noopener noreferrer">www.datadna.in</a></p>
          <p>Email: <strong>projects@datadna.in</strong></p>
        </div>
        <p>By using our Website and services, you acknowledge that you have read and understood this Privacy Policy and agree to our data collection and usage practices.</p>
      </div>
      <Footer1 />
    </div>
  );
};

export default PrivacyPolicy;
