import axios from "axios";
import React, { useState, useEffect } from 'react';
import  "../CSS/HomePage.css";
import Footer from '../Components/Footer';
import Herocards from '../Components/Herocards';
import Navbar from '../Components/Navbar';
import CoreOfferinghome from '../Components/CoreOfferinghome';
import SpecializedOfferings from '../Components/SpecializedOfferings';
import CustomRequirements from '../Components/CustomRequirements';
import Clientlogos from '../Components/Clientlogos';
import Clientlogos1 from '../Components/Clientlogos1';
import { useLocation } from "react-router-dom";
import Loader from '../Components/Loader';

const HomePage=()=>{

  const [isDarkTheme, setIsDarkTheme] = useState(false);

  // Toggle the theme
  const toggleTheme = () => {
    setIsDarkTheme((prevTheme) => !prevTheme);
  };

  // Apply theme class to the body element
  useEffect(() => {
    const body = document.body;
    if (isDarkTheme) {
      body.classList.add('dark-theme');
      body.classList.remove('light-theme');
    } else {
      body.classList.add('light-theme');
      body.classList.remove('dark-theme');
    }
  }, [isDarkTheme]);

  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollTo) {
      const element = document.getElementById(location.state.scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [location]);

   const [hero, setHero1] = useState(null);

   useEffect(() => {
    axios
      .get("https://us-central1-devdatadna.cloudfunctions.net/api/getHero")
      .then((response) => {
        setHero1(response.data);
      })
      .catch((error) => {
        console.error("Error fetching story:", error);
      });
  }, []);

  if (!hero) return <p></p>;
  
  return (
    <div className="homeheadergradient">
    <Loader/>
      {/* Header */}
      <div className="backgroundcolorhm">
    
      {/* <Header/> */}
      <Navbar/>
    
     
      {/* Hero Section */}
      <section className="hero">
        <div className='container'>
          <div className="heroContent">
        
          </div>
        </div>
        <div className="herocards">
              
              <div className="card1">
                  <h3>{hero.Title1}</h3>
                  <p>{hero.Description1}</p>
              </div>
              <div className="card2">
                  <h3>{hero.Title2}</h3>
                  <p>{hero.Description2}</p>
              </div>
              <div className="card3">
                  <h3>{hero.Title3}</h3>
                  <p>{hero.Description3}</p>
              </div>
            
          </div>
      </section>
      </div>
     
      
   
     
      <section id="coreoffering">
      <div>
        <CoreOfferinghome/>
        </div>
      </section>
      
      <h1 className='p-4 text-center mb-4 homepageclientle'>Clientele</h1>
      <div className='clientlogobg'>

      <Clientlogos/>
      <Clientlogos1/>
      </div>
      <section id="specializedOfferings">
        <SpecializedOfferings/>
      </section>
      
      <section id="customRequirements">
        <CustomRequirements/>
      </section>
      <div className='container'>
     
      </div>
     
      <Footer/>
     
      
    </div>
  );
}

export default HomePage;
