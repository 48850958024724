import React from "react"
import Navbar1 from "../../Components/Header"
import Footer1 from "../../Components/Footer1"
import Loader from "../../Components/Loader"
import Tally from "../../Images/Service/tally.png";
import Tax from "../../Images/Service/discount.png";
import Portals from "../../Images/Service/web-portal.png";
import PowerBi from "../../Images/Service/computer.png";
import Document from "../../Images/Service/smart-contracts.png";
import software from "../../Images/Service/software.png";

export const BusinessAdvisery=()=>{
    return(
        <>
        <Loader/>
        <div className="Servicepage">
        <Navbar1/>
        <div className="container">
        <div id="webcrumbs2">
            <div className="w-full max-w-[1280px] bg-gradient-to-br font-sans mx-auto">
                <header className="py-12 md:py-20 px-6 md:px-10 text-center relative overflow-hidden">
                    <div className="absolute top-0 left-0 w-full h-full bg-blue-500 opacity-5 transform -skew-y-6"></div>
                    <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 text-slate-800 relative z-10">
                    Business Advisory Services
                    </h2>
                    <p className="text-lg md:text-xl max-w-4xl mx-auto relative z-10 text-justify">
                    Starting, running, and scaling a business requires strategic planning, financial management, regulatory compliance, and operational efficiency. At DATADNA, we provide end-to-end Business Advisory Services, helping SMEs, and enterprises navigate legal, financial, and tax complexities while ensuring seamless operations.
                    </p>
                    <div className="flex flex-col md:flex-row justify-center mt-8 md:mt-10 space-y-2 md:space-y-0">
                        <button className="bg-blue-600 text-white px-6 md:px-8 py-3 rounded-lg md:mr-4 font-medium transition-all duration-300 hover:bg-blue-700 hover:shadow-lg transform hover:-translate-y-1">
                            Get Started
                        </button>
                       
                    </div>
                    {/* Next: "Add an animated scroll indicator arrow" */}
                </header>

                <section className="py-12 md:py-16 px-6 md:px-10">
                    <h2 className="text-2xl md:text-3xl font-bold text-center mb-10 md:mb-16 text-slate-800">
                        Why Choose Our Business Advisory Services?
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
                        <div className="bg-white p-6 md:p-8 rounded-xl shadow-md transition-all duration-300 hover:shadow-xl hover:-translate-y-2">
                            <div className="bg-blue-100 w-14 h-14 md:w-16 md:h-16 rounded-full flex items-center justify-center mb-6">
                                <span className="material-symbols-outlined text-blue-600 text-2xl md:text-3xl">
                                    smart_toy
                                </span>
                            </div>
                            <h3 className="text-lg md:text-xl font-semibold mb-3 md:mb-4 text-slate-800">
                            Expert-Led Advisory
                            </h3>
                            <p className="text-gray-600 text-justify">
                            Our team of legal, financial, and compliance experts ensures your business is structured for success.
                            </p>
                        </div>

                        <div className="bg-white p-6 md:p-8 rounded-xl shadow-md transition-all duration-300 hover:shadow-xl hover:-translate-y-2">
                            <div className="bg-indigo-100 w-14 h-14 md:w-16 md:h-16 rounded-full flex items-center justify-center mb-6">
                                <span className="material-symbols-outlined text-indigo-600 text-2xl md:text-3xl">
                                    trending_up
                                </span>
                            </div>
                            <h3 className="text-lg md:text-xl font-semibold mb-3 md:mb-4 text-slate-800">
                            End-to-End Business Solutions
                            </h3>
                            <p className="text-gray-600 text-justify">
                            We assist with company registration, compliance, financial planning, tax filing, payroll, and HR policies.
                            </p>
                        </div>

                        <div className="bg-white p-6 md:p-8 rounded-xl shadow-md transition-all duration-300 hover:shadow-xl hover:-translate-y-2">
                            <div className="bg-cyan-100 w-14 h-14 md:w-16 md:h-16 rounded-full flex items-center justify-center mb-6">
                                <span className="material-symbols-outlined text-cyan-600 text-2xl md:text-3xl">
                                    security
                                </span>
                            </div>
                            <h3 className="text-lg md:text-xl font-semibold mb-3 md:mb-4 text-slate-800">
                            Regulatory Compliance & Risk Management
                            </h3>
                            <p className="text-gray-600 text-justify">
                            We ensure your business adheres to all legal, financial, and statutory regulations.
                            </p>
                        </div>
                    </div>
                    {/* Next: "Add a 'View all features' button" */}
                </section>

                <section className="py-12 md:py-16 px-6 md:px-10 bg-gradient-to-r from-slate-800 to-blue-900 text-white">
                    <h2 className="text-2xl md:text-3xl font-bold text-center mb-10 md:mb-16"> Our Business Advisory Services</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
                        <div className="relative">
                            <div className="absolute -left-3 -top-3 md:-left-4 md:-top-4 w-12 h-12 md:w-16 md:h-16 bg-blue-500 opacity-20 rounded-lg"></div>
                            <div className="relative z-10 border border-blue-400 p-6 md:p-8 rounded-lg bg-blue-900 bg-opacity-30 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-50 hover:shadow-lg">
                                <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 flex items-center">
                                    <span className="material-symbols-outlined mr-2 md:mr-3 text-blue-300">
                                        insights
                                    </span>
                                    Company Formation & Business Structuring
                                </h3>
                                <h6 className="mb-4 text-justify">Setting up a business involves choosing the right legal structure and fulfilling compliance requirements.</h6>
                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-blue-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Company Incorporation</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-blue-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>GST Registration, MSME Registration, and Trade Licenses</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-blue-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Business Structuring & Legal Documentation</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-blue-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Bank Account Setup & Digital Compliance</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="relative">
                            <div className="absolute -left-3 -top-3 md:-left-4 md:-top-4 w-12 h-12 md:w-16 md:h-16 bg-indigo-500 opacity-20 rounded-lg"></div>
                            <div className="relative z-10 border border-indigo-400 p-6 md:p-8 rounded-lg bg-indigo-900 bg-opacity-30 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-50 hover:shadow-lg">
                                <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 flex items-center">
                                    <span className="material-symbols-outlined mr-2 md:mr-3 text-indigo-300">
                                        auto_awesome
                                    </span>
                                    Regulatory Compliance & Taxation
                                </h3>
                                <h6 className="mb-4">We handle all regulatory compliance, ensuring your business meets statutory and tax obligations seamlessly.</h6>
                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-indigo-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>GST Filing & Compliance</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-indigo-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Income Tax & Corporate Tax Filing</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-indigo-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>ROC (Registrar of Companies) Filings</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-indigo-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Annual Returns & Financial Reporting</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="relative">
                            <div className="absolute -left-3 -top-3 md:-left-4 md:-top-4 w-12 h-12 md:w-16 md:h-16 bg-cyan-500 opacity-20 rounded-lg"></div>
                            <div className="relative z-10 border border-cyan-400 p-6 md:p-8 rounded-lg bg-cyan-900 bg-opacity-30 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-50 hover:shadow-lg">
                                <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 flex items-center">
                                    <span className="material-symbols-outlined mr-2 md:mr-3 text-cyan-300">
                                        person_search
                                    </span>
                                    Finance, Accounting & Tax Compliance
                                </h3>
                                <h6 className="mb-4 text-justify">A well-managed financial system is critical for business growth. We provide end-to-end accounting and tax compliance solutions.</h6>
                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-cyan-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Bookkeeping & Accounting Services</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-cyan-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Financial Audits & Budget Planning</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-cyan-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Profit & Loss Analysis</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-cyan-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Direct & Indirect Tax Advisory</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="relative">
                            <div className="absolute -left-3 -top-3 md:-left-4 md:-top-4 w-12 h-12 md:w-16 md:h-16 bg-purple-500 opacity-20 rounded-lg"></div>
                            <div className="relative z-10 border border-purple-400 p-6 md:p-8 rounded-lg bg-purple-900 bg-opacity-30 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-50 hover:shadow-lg">
                                <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 flex items-center">
                                    <span className="material-symbols-outlined mr-2 md:mr-3 text-purple-300">
                                        trending_up
                                    </span>
                                    Payroll Administration & Statutory Compliance
                                </h3>
                                <h6 className="mb-4 text-justify">Managing payroll and employee benefits while ensuring statutory compliance is crucial for any business.</h6>
                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Payroll Processing & Salary Structuring</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>EPF, ESI, Gratuity, and Bonus Compliance</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>TDS (Tax Deducted at Source) Compliance</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Labor Law Compliance & Employee Benefit Programs</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="relative">
                            <div className="absolute -left-3 -top-3 md:-left-4 md:-top-4 w-12 h-12 md:w-16 md:h-16 bg-purple-500 opacity-20 rounded-lg"></div>
                            <div className="relative z-10 border border-purple-400 p-6 md:p-8 rounded-lg bg-purple-900 bg-opacity-30 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-50 hover:shadow-lg">
                                <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 flex items-center">
                                    <span className="material-symbols-outlined mr-2 md:mr-3 text-purple-300">
                                        trending_up
                                    </span>
                                    Business Plan Formations & Strategic Advisory
                                </h3>
                                <h6 className="mb-4 text-justify">A strong business plan and financial strategy are essential for funding and long-term success.</h6>
                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Business Plan Development & Pitch Decks</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Financial Projections & Valuation Reports</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Funding & Investment Advisory</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Market Research & Competitor Analysis</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="relative">
                            <div className="absolute -left-3 -top-3 md:-left-4 md:-top-4 w-12 h-12 md:w-16 md:h-16 bg-purple-500 opacity-20 rounded-lg"></div>
                            <div className="relative z-10 border border-purple-400 p-6 md:p-8 rounded-lg bg-purple-900 bg-opacity-30 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-50 hover:shadow-lg">
                                <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 flex items-center">
                                    <span className="material-symbols-outlined mr-2 md:mr-3 text-purple-300">
                                        trending_up
                                    </span>
                                    Agreements, HR Policies & Legal Documentation
                                </h3>
                                <h6 className="mb-4 text-justify">We help businesses create legally compliant contracts, HR policies, and operational agreements to avoid risks.</h6>
                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Employment Contracts & Offer Letters</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Vendor Agreements & Partnership Contracts</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Company Policy & HR Policy Frameworks</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Non-Disclosure Agreements (NDA) & Service Agreements</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Next: "Add a case study section with real-world examples" */}
                </section>

                <section className="py-12 md:py-16 px-6 md:px-10">
                    <h2 className="text-2xl md:text-3xl font-bold text-center mb-10 md:mb-16 text-slate-800">
                    Business Advisory Metrics & Statistics
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 mb-10 md:mb-16">
                        <div className="bg-gradient-to-br from-blue-500 to-indigo-600 p-6 md:p-8 rounded-xl text-white shadow-lg transform transition-transform duration-300 hover:scale-105">
                            <h3 className="text-4xl md:text-5xl font-bold mb-2 text-center">65%</h3>
                            <p className="text-blue-100 text-base md:text-lg text-justify">
                            Reduction in risks when Companies are setup with structured HR policies & payroll
                            </p>
                        </div>

                        <div className="bg-gradient-to-br from-indigo-500 to-purple-600 p-6 md:p-8 rounded-xl text-white shadow-lg transform transition-transform duration-300 hover:scale-105">
                            <h3 className="text-4xl md:text-5xl font-bold mb-2 text-center">2.5X</h3>
                            <p className="text-indigo-100 text-base md:text-lg text-justify">
                            faster than those without a business plan.
                            </p>
                        </div>

                        <div className="bg-gradient-to-br from-cyan-500 to-blue-600 p-6 md:p-8 rounded-xl text-white shadow-lg transform transition-transform duration-300 hover:scale-105">
                            <h3 className="text-4xl md:text-5xl font-bold mb-2 text-center">40%</h3>
                            <p className="text-cyan-100 text-base md:text-lg text-justify">
                            higher operational efficiency for Companies with automated payroll & compliance systems report
                            </p>
                        </div>
                    </div>

                
                    
                </section>
                <section className="py-12 md:py-16 px-4 md:px-6">
                    <div className="text-center mb-8 md:mb-12 ">
                        <h2 className="text-2xl md:text-3xl font-bold mb-3 md:mb-4">
                        Tools & Technologies
                        </h2>
                        <p className="max-w-4xl mx-auto">
                        We leverage the latest business management and compliance tools for seamless advisory solutions
                        </p>
                    </div>

                    {/* Updated grid for 2 rows and 3 columns */}
                                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
                                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-blue-50 flex flex-col items-center">
                                        <div className="w-16 h-16 mb-4 flex items-center justify-center">
                                            <i className="fa-brands text-5xl text-blue-500"><img src={Tally} alt="Tally"></img></i>
                                        </div>
                                        <h3 className="font-semibold text-lg mb-2">Tally ERP / Zoho Books / QuickBooks</h3>
                                        <p className="text-gray-600 text-sm text-center">
                                            For accounting & bookkeeping automation.
                                        </p>
                                        </div>

                                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-green-50 flex flex-col items-center">
                                        <div className="w-16 h-16 mb-4 flex items-center justify-center">
                                            <i className="fa-brands text-5xl text-green-600"><img src={Tax} alt="ClearTax"></img></i>
                                        </div>
                                        <h3 className="font-semibold text-lg mb-2">GST Suvidha Kendra / ClearTax</h3>
                                        <p className="text-gray-600 text-sm text-center">
                                        For GST filing & tax compliance.
                                        </p>
                                        </div>

                                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-green-50 flex flex-col items-center">
                                        <div className="w-16 h-16 mb-4 flex items-center justify-center">
                                            <i className="fa-brands text-5xl text-green-600"><img src={Portals} alt="Portals"></img></i>
                                        </div>
                                        <h3 className="font-semibold text-lg mb-2">EPFO & ESI Portals</h3>
                                        <p className="text-gray-600 text-sm text-center">
                                        For employee statutory benefits compliance.
                                        </p>
                                        </div>

                                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-red-50 flex flex-col items-center">
                                        <div className="w-16 h-16 mb-4 flex items-center justify-center">
                                            <i className="fa-brands text-5xl text-red-600"><img src={PowerBi} alt="PowerBI"></img></i>
                                        </div>
                                        <h3 className="font-semibold text-lg mb-2">Microsoft Power BI / Google Data Studio</h3>
                                        <p className="text-gray-600 text-sm text-center">
                                        For financial analytics & business reporting.
                                        </p>
                                        </div>

                                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-blue-50 flex flex-col items-center">
                                        <div className="w-16 h-16 mb-4 flex items-center justify-center">
                                            <i className="fa-brands text-5xl text-orange-500"><img src={Document} alt="Document"></img></i>
                                        </div>
                                        <h3 className="font-semibold text-lg mb-2">LegalDesk / DocuSign</h3>
                                        <p className="text-gray-600 text-sm text-center">
                                        For digital contracts & legal documentation.
                                        </p>
                                        </div>

                                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-purple-50 flex flex-col items-center">
                                        <div className="w-16 h-16 mb-4 flex items-center justify-center">
                                            <i className="fa-brands text-5xl text-blue-600"><img src={software} alt="Software"></img></i>
                                        </div>
                                        <h3 className="font-semibold text-lg mb-2">HRMS Software</h3>
                                        <p className="text-gray-600 text-sm text-center">
                                        For payroll, compliance & HR policies.
                                        </p>
                                        </div>
                                    </div>
                                    </section>



                <section className="py-12 md:py-16 px-6 md:px-10">
                    <h2 className="text-2xl md:text-3xl font-bold text-center mb-6 md:mb-10 text-slate-800">
                        Ready to Transform Your Business?
                    </h2>
                    <p className="text-center text-base md:text-lg max-w-4xl mx-auto mb-8 md:mb-10">
                    Let us take care of your business foundation, finances, compliance, and operations—so you can focus on growth.
                    </p>
                    <div className="flex justify-center">
                        <button className="bg-blue-600 text-white px-6 md:px-8 py-3 md:py-4 rounded-lg font-medium text-base md:text-lg transition-all duration-300 hover:bg-blue-700 hover:shadow-xl transform hover:-translate-y-1">
                            Schedule a Consultation
                        </button>
                    </div>
                    {/* Next: "Add a newsletter signup form with email validation" */}
                </section>
            </div>
        </div>
        </div>
        <Footer1/>
        </div>
        </>
    );
}
