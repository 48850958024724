import React,{useState, useEffect} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ContactPage from "./Pages/ContactPage";
import SEO from "./Pages/SEOPage";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import HomePage from "./Pages/HomePage";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsAndConditions from "./Pages/TermsandCondition";
import RefundPolicy from "./Pages/RefundPolicy";
import { About } from "./Pages/AboutPage";
import { Pricing } from "./Pages/Pricing";
import { ServicePage } from "./Pages/ServicePage";
import { ProductDevelopmentPage } from "./Pages/AllServicesPage/ProductDevelopmentPage";
import { UIUXPage } from "./Pages/AllServicesPage/UIUXPage";
import { FullStackPage } from "./Pages/AllServicesPage/FullStackPage";
import { AIPage } from "./Pages/AllServicesPage/AIPage";
import { MobileAppDevelopmentPage } from "./Pages/AllServicesPage/MobileAppDevelopmentPage";
import { CloudSolutionsPage } from "./Pages/AllServicesPage/CloudSolutionsPage";
import { DigitalTransformationPage } from "./Pages/AllServicesPage/DigitalTransformationPage";
import { ProcessWorkflowPage } from "./Pages/AllServicesPage/ProcessWorkflowPage";
import {BusinessAdvisery} from "./Pages/AllServicesPage/BusinessAdvisery";

const App=()=>{

  
const [metaTitle, setMetaTitle] = useState('');
const [keywords, setKeywords] = useState('');
const [primaryKeywords, setPrimaryKeywords] = useState('');
const [secondaryKeywords, setSecondaryKeywords] = useState('');
const [message3, setMessage3] = useState(''); 


useEffect(() => {
  const metaDataRef = firebase.database().ref('metadata');

  // Fetch metadata from the database
  metaDataRef.once('value').then((snapshot) => {
      const metaData = snapshot.val();
      if (metaData) {
          setMetaTitle(metaData.metaTitle || '');
          setKeywords(metaData.keywords || '');
          setPrimaryKeywords(metaData.primaryKeywords || '');
          setSecondaryKeywords(metaData.secondaryKeywords || '');

      }
  }).catch((error) => {
      console.error('Error fetching metadata:', error);
  });
}, []);

  useEffect(() => {



    const keywordsMeta = document.querySelector('meta[name="keywords"]');
    const primaryKeywordsMeta = document.querySelector('meta[name="primary keywords"]');
    const secondaryKeywordsMeta = document.querySelector('meta[name="secondary keywords"]');

    console.log('Keywords meta content:', keywordsMeta ? keywordsMeta.content : null);
    console.log('Primary keywords meta content:', primaryKeywordsMeta ? primaryKeywordsMeta.content : null);
    console.log('Secondary keywords meta content:', secondaryKeywordsMeta ? secondaryKeywordsMeta.content : null);

    if (keywordsMeta) {
        keywordsMeta.setAttribute('content', keywords);
    }

    if (primaryKeywordsMeta) {
        primaryKeywordsMeta.setAttribute('content', primaryKeywords);
    }

    if (secondaryKeywordsMeta) {
        secondaryKeywordsMeta.setAttribute('content', secondaryKeywords);
    }
}, [metaTitle,keywords, primaryKeywords, secondaryKeywords]);

    return (
        <BrowserRouter>
        
          <Routes>
            <Route path="/" element={<HomePage/>}/>
            <Route path="/contact" element={<ContactPage/>}/>
            <Route path="/seo" element={<SEO/>}/>
            <Route path="/privacypolicy" element={<PrivacyPolicy/>}/>
            <Route path="/termsandcondition" element={<TermsAndConditions/>}/>
            <Route path="/refundpolicy" element={<RefundPolicy/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/pricing" element={<Pricing/>}/>
            <Route path="/service" element={<ServicePage/>}/>

            <Route path="/service/ProductDevelopment" element={<ProductDevelopmentPage/>}/>
            <Route path="/service/UIUX" element={<UIUXPage/>}/>
            <Route path="/service/FullStack" element={<FullStackPage/>}/>
            <Route path="/service/AI" element={<AIPage/>}/>
            <Route path="/service/MobileAppDevelopment" element={<MobileAppDevelopmentPage/>}/>
            <Route path="/service/CloudSolutions" element={<CloudSolutionsPage/>}/>
            <Route path="/service/DigitalTransformation" element={<DigitalTransformationPage/>}/>
            <Route path="/service/ProcessWorkflow" element={<ProcessWorkflowPage/>}/>
            <Route path="/service/BusinessAdvisory" element={<BusinessAdvisery/>}/>
          </Routes>
        </BrowserRouter>
    );
}

export default App;