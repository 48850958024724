import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";
import CreativeRec from "../Images/project/CreativeRec.png";
import FingerPrint from "../Images/project/FingerPrint.png";
import Finopia from "../Images/project/Finopia.png";
import martellato from "../Images/project/martellato.png";
import mckinsey from "../Images/project/mckinsey.png";
import parua from "../Images/project/Parua.png";
import pk from "../Images/project/PK.png";
import promantus from "../Images/project/promantus.png";
import "../CSS/Clientlogos.css";

const Clientlogos = () => {
  const logos = [
    { src: CreativeRec, alt: "Creative Recreational Systems" },
    { src: FingerPrint, alt: "FingerPrint for Success" },
    { src: Finopia, alt: "Finopia" },
    { src: martellato, alt: "Martellato" },
    { src: parua, alt: "Parua" },
    { src: mckinsey, alt: "McKinsey" },
    { src: pk, alt: "PK Enterprises" },
    { src: promantus, alt: "Promantus" }
  ];

  return (
    <div className="clientlogos">
      <Swiper
        spaceBetween={window.innerWidth < 768 ? 10 : 20} // Adjust spacing for mobile
        slidesPerView={window.innerWidth < 768 ? 2 : 4} // Show fewer slides on mobile
        loop={true}
        autoplay={{
          delay: 400,
          disableOnInteraction: true,
          pauseOnMouseEnter: false,
          reverseDirection: false,
          stopOnLastSlide: false,
          speed: 5000,
        }}
        allowTouchMove={true} // Allow swipe on mobile
        modules={[Autoplay]}
      >
        {[...logos, ...logos].map((logo, index) => (
          <SwiperSlide key={index}>
            <div
              className="divclientlogs"
              style={{
                width: "120px",
                height: "90px",
                margin: window.innerWidth < 768 ? "5px" : "0px 20px",
              }}
            >
              <img
                src={logo.src}
                alt={logo.alt}
                style={{
                  width: "100px",
                  height: "80px",
                  padding: window.innerWidth < 768 ? "5px" : "0px",
                }}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Clientlogos;
