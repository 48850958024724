import React, { useState, useEffect } from 'react';
import axios from "axios";
import { FaUsers, FaHandshake, FaProjectDiagram } from "react-icons/fa";
import "../CSS/CustomRequirements.css";
import stafing from "../Images/Staffingr.gif";
import Contract from "../Images/Contractr.gif";
import Short from "../Images/Shortterm.gif";



const CustomRequirements = () => {
    
    const [customData, setCustomData] = useState(null);

    useEffect(() => {
     axios
       .get("https://us-central1-devdatadna.cloudfunctions.net/api/getCustomRequirements")
       .then((response) => {
         setCustomData(response.data);
       })
       .catch((error) => {
         console.error("Error fetching story:", error);
       });
   }, []);
 
   if (!customData) return <p></p>;

   const customRequirements = [
    { icon: <img src={stafing} alt={customData.Title1} />, title: customData.Title1, description: customData.Description1 },
    { icon: <img src={Contract} alt={customData.Title2} />, title: customData.Title2, description:customData.Description2 },
    { icon: <img src={Short} alt={customData.Title3} />, title: customData.Title3, description: customData.Description3 },
    ];

    return (
        <div className="custom-requirements  mb-4 p-4">
        <div className="container mb-4 p-4">
          <h1 className="p-4 text-center mb-4"  style={{color:"#7c34a8"}}>Custom Requirements</h1>
        <div className="customrequirements">
        
            {customRequirements.map((service, index) => (
                <div key={index} className="requirement-card">
                    <div className="customreqicon">{service.icon}</div>
                    <h3>{service.title}</h3>
                    <p>{service.description}</p>
                    {/* <span>Learn More</span> */}
                </div>
            ))}
        </div>
        </div>
        </div>
    );
};

export default CustomRequirements;
