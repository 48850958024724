import React from "react"
import Navbar1 from "../../Components/Header"
import Footer1 from "../../Components/Footer1"
import Loader from "../../Components/Loader"
import AI from "../../Images/Service/artificial-intelligence.png";
import Automation from "../../Images/Service/automation.png";
import Cyber from "../../Images/Service/cyber-security.png";
import block from "../../Images/Service/block.png";
import analytics from "../../Images/Service/analytics.png";

export const DigitalTransformationPage = () => {
    return (
        <>
        <Loader/>
        <div className="Servicepage">
        <Navbar1/>
        <div className="container">
        <div id="webcrumbs2">
            <div className="w-full max-w-[1280px] bg-gradient-to-br font-sans mx-auto">
                <header className="py-12 md:py-20 px-6 md:px-10 text-center relative overflow-hidden">
                    <div className="absolute top-0 left-0 w-full h-full bg-blue-500 opacity-5 transform -skew-y-6"></div>
                    <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 text-slate-800 relative z-10">
                        Digital Transformation Service
                    </h2>
                    <p className="text-lg md:text-xl max-w-3xl mx-auto relative z-10">
                    At DATADNA, we drive end-to-end digital transformation to help businesses embrace cutting-edge technologies, enhance customer experiences, and optimize operational efficiency.
                    </p>
                    <div className="flex flex-col md:flex-row justify-center mt-8 md:mt-10 space-y-2 md:space-y-0">
                        <button className="bg-blue-600 text-white px-6 md:px-8 py-3 rounded-lg md:mr-4 font-medium transition-all duration-300 hover:bg-blue-700 hover:shadow-lg transform hover:-translate-y-1">
                            Get Started
                        </button>
                     
                    </div>
                    {/* Next: "Add an animated scroll indicator arrow" */}
                </header>

                <section className="py-12 md:py-16 px-6 md:px-10">
                    <h2 className="text-2xl md:text-3xl font-bold text-center mb-10 md:mb-16 text-slate-800">
                        Why Choose Our  Digital Transformation Services
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
                        <div className="bg-white p-6 md:p-8 rounded-xl shadow-md transition-all duration-300 hover:shadow-xl hover:-translate-y-2">
                            <div className="bg-blue-100 w-14 h-14 md:w-16 md:h-16 rounded-full flex items-center justify-center mb-6">
                                <span className="material-symbols-outlined text-blue-600 text-2xl md:text-3xl">
                                    smart_toy
                                </span>
                            </div>
                            <h3 className="text-lg md:text-xl font-semibold mb-3 md:mb-4 text-slate-800">
                                End-to-End Digital Transformation
                            </h3>
                            <p className="text-gray-600 text-justify">
                            We create a custom roadmap tailored to your business goals.
                            </p>
                        </div>

                        <div className="bg-white p-6 md:p-8 rounded-xl shadow-md transition-all duration-300 hover:shadow-xl hover:-translate-y-2">
                            <div className="bg-indigo-100 w-14 h-14 md:w-16 md:h-16 rounded-full flex items-center justify-center mb-6">
                                <span className="material-symbols-outlined text-indigo-600 text-2xl md:text-3xl">
                                    trending_up
                                </span>
                            </div>
                            <h3 className="text-lg md:text-xl font-semibold mb-3 md:mb-4 text-slate-800">
                            Data-Driven Insights & Analytics
                            </h3>
                            <p className="text-gray-600 text-justify">
                            Make informed decisions with real-time analytics & predictive insights.
                            </p>
                        </div>

                        <div className="bg-white p-6 md:p-8 rounded-xl shadow-md transition-all duration-300 hover:shadow-xl hover:-translate-y-2">
                            <div className="bg-cyan-100 w-14 h-14 md:w-16 md:h-16 rounded-full flex items-center justify-center mb-6">
                                <span className="material-symbols-outlined text-cyan-600 text-2xl md:text-3xl">
                                    security
                                </span>
                            </div>
                            <h3 className="text-lg md:text-xl font-semibold mb-3 md:mb-4 text-slate-800">
                            Agile & Scalable Implementation
                            </h3>
                            <p className="text-gray-600 text-justify">
                            We design digital solutions that scale as your business grows.

                            </p>
                        </div>
                    </div>
                    {/* Next: "Add a 'View all features' button" */}
                </section>

                <section className="py-12 md:py-16 px-6 md:px-10 bg-gradient-to-r from-slate-800 to-blue-900 text-white">
                    <h2 className="text-2xl md:text-3xl font-bold text-center mb-10 md:mb-16">Our Digital Transformation Offerings</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
                        <div className="relative">
                            <div className="absolute -left-3 -top-3 md:-left-4 md:-top-4 w-12 h-12 md:w-16 md:h-16 bg-blue-500 opacity-20 rounded-lg"></div>
                            <div className="relative z-10 border border-blue-400 p-6 md:p-8 rounded-lg bg-blue-900 bg-opacity-30 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-50 hover:shadow-lg">
                                <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 flex items-center">
                                    <span className="material-symbols-outlined mr-2 md:mr-3 text-blue-300">
                                        insights
                                    </span>
                                    Cloud Transformation & Migration
                                </h3>
                                <p className="mb-4">We enable seamless cloud adoption to drive agility, scalability, and cost efficiency processes.</p>
                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-blue-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Cloud strategy, consulting & implementation</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-blue-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Migration from on-premise to AWS, Azure, or Google Cloud</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-blue-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>SaaS, PaaS, and IaaS deployment for optimized performance</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-blue-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Security & compliance management in the cloud</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="relative">
                            <div className="absolute -left-3 -top-3 md:-left-4 md:-top-4 w-12 h-12 md:w-16 md:h-16 bg-indigo-500 opacity-20 rounded-lg"></div>
                            <div className="relative z-10 border border-indigo-400 p-6 md:p-8 rounded-lg bg-indigo-900 bg-opacity-30 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-50 hover:shadow-lg">
                                <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 flex items-center">
                                    <span className="material-symbols-outlined mr-2 md:mr-3 text-indigo-300">
                                        auto_awesome
                                    </span>
                                    Business Process Automation (BPA)
                                </h3>
                                <p className="mb-4">We automate manual workflows to enhance efficiency, reduce errors, and accelerate processes.</p>
                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-indigo-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>AI-powered process automation</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-indigo-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>RPA-driven repetitive task automation</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-indigo-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>ERP, CRM, and HRMS automation</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-indigo-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Low-code & no-code workflow automation</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="relative">
                            <div className="absolute -left-3 -top-3 md:-left-4 md:-top-4 w-12 h-12 md:w-16 md:h-16 bg-cyan-500 opacity-20 rounded-lg"></div>
                            <div className="relative z-10 border border-cyan-400 p-6 md:p-8 rounded-lg bg-cyan-900 bg-opacity-30 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-50 hover:shadow-lg">
                                <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 flex items-center">
                                    <span className="material-symbols-outlined mr-2 md:mr-3 text-cyan-300">
                                        person_search
                                    </span>
                                    Data & AI-Driven Digital Strategy
                                </h3>
                                <p className="mb-4">We harness the power of AI, Big Data, and Predictive Analytics to enable data-driven decision-making.</p>
                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-cyan-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Business intelligence & analytics solutions</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-cyan-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>AI-powered customer insights & personalization</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-cyan-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Predictive analytics for demand forecasting</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-cyan-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Real-time data visualization & dashboards</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="relative">
                            <div className="absolute -left-3 -top-3 md:-left-4 md:-top-4 w-12 h-12 md:w-16 md:h-16 bg-purple-500 opacity-20 rounded-lg"></div>
                            <div className="relative z-10 border border-purple-400 p-6 md:p-8 rounded-lg bg-purple-900 bg-opacity-30 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-50 hover:shadow-lg">
                                <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 flex items-center">
                                    <span className="material-symbols-outlined mr-2 md:mr-3 text-purple-300">
                                        trending_up
                                    </span>
                                    Customer Experience & Digital Commerce
                                </h3>
                                <p className="mb-4">We help brands enhance customer journeys through personalized, seamless, and omnichannel experiences.</p>
                                <ul className="space-y-3">
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>E-commerce transformation & digital payments</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>AI-powered chatbots & conversational AI</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>UX/UI optimization for better engagement</span>
                                    </li>
                                    <li className="flex items-start">
                                        <span className="material-symbols-outlined text-purple-300 mr-2 mt-1 text-sm md:text-base">
                                            check_circle
                                        </span>
                                        <span>Omnichannel marketing automation</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Next: "Add a case study section with real-world examples" */}
                </section>

                <section className="py-12 md:py-16 px-6 md:px-10">
                    <h2 className="text-2xl md:text-3xl font-bold text-center mb-10 md:mb-16 text-slate-800">
                    Digital Transformation Metrics & Statistics
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 mb-10 md:mb-16">
                        <div className="bg-gradient-to-br from-blue-500 to-indigo-600 p-6 md:p-8 rounded-xl text-white shadow-lg transform transition-transform duration-300 hover:scale-105">
                            <h3 className="text-4xl md:text-5xl font-bold mb-2 text-center">23%</h3>
                            <p className="text-blue-100 text-base md:text-lg">
                            more profitable than non-digital competitors.
                            </p>
                        </div>

                        <div className="bg-gradient-to-br from-indigo-500 to-purple-600 p-6 md:p-8 rounded-xl text-white shadow-lg transform transition-transform duration-300 hover:scale-105">
                            <h3 className="text-4xl md:text-5xl font-bold mb-2 text-center">40-50%</h3>
                            <p className="text-indigo-100 text-base md:text-lg">
                            reduced IT infrastructure costs by Cloud adoption
                            </p>
                        </div>

                        <div className="bg-gradient-to-br from-cyan-500 to-blue-600 p-6 md:p-8 rounded-xl text-white shadow-lg transform transition-transform duration-300 hover:scale-105">
                            <h3 className="text-4xl md:text-5xl font-bold mb-2 text-center">2.5</h3>
                            <p className="text-cyan-100 text-base md:text-lg">
                            times faster growth for Digital firms than traditional businesses.
                            </p>
                        </div>
                    </div>

                    {/* <div className="bg-white p-6 md:p-10 rounded-2xl shadow-xl">
                        <h3 className="text-xl md:text-2xl font-semibold mb-6 md:mb-8 text-slate-800 text-center">
                            AI Adoption by Industry
                        </h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-10">
                            <div className="space-y-6">
                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Healthcare</span>
                                        <span className="font-medium">76%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[76%] transition-all duration-1000"></div>
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Financial Services</span>
                                        <span className="font-medium">82%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[82%] transition-all duration-1000"></div>
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Retail</span>
                                        <span className="font-medium">68%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[68%] transition-all duration-1000"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="space-y-6">
                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Manufacturing</span>
                                        <span className="font-medium">71%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[71%] transition-all duration-1000"></div>
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Transportation</span>
                                        <span className="font-medium">64%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[64%] transition-all duration-1000"></div>
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-medium">Energy & Utilities</span>
                                        <span className="font-medium">59%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-600 h-2.5 rounded-full w-[59%] transition-all duration-1000"></div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>*/}
                    
                </section>
                <section className="py-12 md:py-16 px-4 md:px-6">
                    <div className="text-center mb-8 md:mb-12">
                        <h2 className="text-2xl md:text-3xl font-bold mb-3 md:mb-4">Tools & Technologies</h2>
                        <p className="max-w-2xl mx-auto">
                        We leverage leading-edge technologies to streamline and accelerate digital transformation
                        </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-blue-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                                <i className="fa-brands fa-aws text-5xl text-blue-500"></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2">AWS / Azure / Google Cloud</h3>
                            <p className="text-gray-600 text-sm text-justify">
                            For cloud computing & infrastructure modernization.
                            </p>
                        </div>

                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-green-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                                <i className="fa-brands text-5xl text-green-600"><img src={AI} alt="AI"/></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2">AI & Machine Learning</h3>
                            <p className="text-gray-600 text-sm text-justify">
                            For smart automation and predictive analytics.
                            </p>
                        </div>

                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-green-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                            <i className="fa-brands text-5xl text-green-600"><img src={Automation} alt="Automation"/></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2"> RPA</h3>
                            <p className="text-gray-600 text-sm text-justify">To eliminate repetitive tasks and enhance efficiency.</p>
                        </div>

                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-red-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                            <i className="fa-brands text-5xl text-green-600"><img src={Cyber} alt="Cyber"/></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2">Big Data & Analytics</h3>
                            <p className="text-gray-600 text-sm text-justify">
                            For real-time insights and business intelligence.
                            </p>
                        </div>

                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-blue-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                            <i className="fa-brands text-5xl text-green-600"><img src={block} alt="block"/></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2">Blockchain & IoT</h3>
                            <p className="text-gray-600 text-sm text-justify">
                            For secure, decentralized operations and intelligent connectivity.
                            </p>
                        </div>

                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-purple-50 flex flex-col items-center">
                            <div className="w-16 h-16 mb-4 flex items-center justify-center">
                            <i className="fa-brands text-5xl text-green-600"><img src={analytics} alt="Analytics"/></i>
                            </div>
                            <h3 className="font-semibold text-lg mb-2">Cybersecurity & Compliance</h3>
                            <p className="text-gray-600 text-sm text-justify">
                            To protect digital assets and data.
                            </p>
                        </div>
                    </div>
                </section>


                <section className="py-12 md:py-16 px-6 md:px-10">
                    <h2 className="text-2xl md:text-3xl font-bold text-center mb-6 md:mb-10 text-slate-800">
                    Digitize Your Business
                    </h2>
                    <p className="text-justify text-base md:text-lg max-w-3xl mx-auto mb-8 md:mb-10">
                    By embracing digital transformation, businesses unlock new opportunities, streamline operations, and enhance customer experiences.
                    </p>
                    <div className="flex justify-center">
                        <button className="bg-blue-600 text-white px-6 md:px-8 py-3 md:py-4 rounded-lg font-medium text-base md:text-lg transition-all duration-300 hover:bg-blue-700 hover:shadow-xl transform hover:-translate-y-1">
                            Schedule a Consultation
                        </button>
                    </div>
                    {/* Next: "Add a newsletter signup form with email validation" */}
                </section>
            </div>
        </div>
        </div>
        <Footer1/>
        </div>
        </>
    )
}