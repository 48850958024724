import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../CSS/Footer.css";
import '@fortawesome/fontawesome-free/css/all.css';
import Location from "../Images/map.gif";
import Phone from "../Images/phonegif.gif";
import Email from "../Images/emailgif.gif";
import FloatingIcons from "./FloatingIcons";

const Footer1=()=>{
   const location = useLocation();
    const navigate = useNavigate();
    const handleNavigation = (event, sectionId) => {
        event.preventDefault(); // Prevent default anchor behavior
      
        if (location.pathname !== "/") {
          // Navigate to home page first
          navigate("/", { state: { scrollTo: sectionId } });
        } else {
          // If already on home, scroll immediately
          scrollToSection(sectionId);
        }
      };
      
      const scrollToSection = (sectionId) => {
        setTimeout(() => {
          const element = document.getElementById(sectionId);
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }, 100); // Timeout to ensure navigation completes first
      };

    return(
        <footer className="ftco-footer ftco-section img">
    <div className="overlay" />
    <div className="container1">
      <div className="row mb-5">
        <div className="col-md-3">
          <div className="ftco-footer-widget mb-4">
            <h2 className="ftco-heading-2">About Us</h2>
            <p className="text-justify">
            
          At DATADNA, we are a team of experienced professionals who are passionate about technology and its potential to transform businesses.
            </p>
            <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
              <li className="ftco-animate">
                <a  href="https://www.facebook.com/datadnalearning" target="_blank" className="social-button social-button--facebook">
                <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li className="ftco-animate">
                <a href="https://www.linkedin.com/company/datadna-in/" className="social-button social-button--youtube">
                  <i className="fab fa-linkedin"></i>
                </a>
              </li>
              <li className="ftco-animate">
                <a href="https://www.instagram.com/datadna.in/"  target="_blank"  className="social-button social-button--instagram">
                <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
       
        <div className="col-md-2">
          <div className="ftco-footer-widget  ml-md-4">
            <h2 className="ftco-heading-2">Links</h2>
            <ul className="list-unstyled">
              <li>
                <a href="/" className="py-2 d-block">
                Home
                </a>
              </li>
              <li>
                <a href="/about" className="py-2 d-block">
                About
                </a>
              </li>
              <li>
                <a href="/" onClick={(e) => handleNavigation(e, "coreoffering")} className="py-2 d-block">
                Core Offering
                </a>
              </li>
              <li>
                <a href="/" onClick={(e) => handleNavigation(e, "specializedOfferings")} className="py-2 d-block">
                Specialized Offerings
                </a>
              </li>
              <li>
                <a href="/" onClick={(e) => handleNavigation(e, "customRequirements")} className="py-2 d-block">
                Resourcing
                </a>
              </li>
              <li>
                <a href="/contact" className="py-2 d-block">
                Contact
                </a>
              </li>
             
            </ul>
          </div>
        </div>
        <div className="col-md-3">
          <div className="ftco-footer-widget mb-4">
            <h2 className="ftco-heading-2 text-center">Service</h2>
            <div className="row">
              <div className="col-6">
                <ul className="list-unstyled">
                  <li><a href="/service/ProductDevelopment" className="py-2 d-block">Product Development</a></li>
                  <li><a href="/service/UIUX" className="py-2 d-block">UI/UX</a></li>
                  <li><a href="/service/FullStack" className="py-2 d-block">Full Stack</a></li>
                  <li><a href="/service/AI" className="py-2 d-block">AI</a></li>
                </ul>
              </div>
              <div className="col-6">
                <ul className="list-unstyled">
                  <li><a href="/service/CloudSolutions" className="py-2 d-block">Cloud Solutions</a></li>
                  <li><a href="/service/DigitalTransformation" className="py-2 d-block">Digital Transformation</a></li>
                  <li><a href="/service/ProcessWorkflow" className="py-2 d-block">Process Workflow</a></li>
                  <li><a href="/service/MobileAppDevelopment" className="py-2 d-block">Mobile Development</a></li>
                </ul>
              </div>
            </div>
            <ul className="list-unstyled">
            <li><a href="/service/BusinessAdvisory" className="py-2 d-block text-center">Business Advisory</a></li>
            </ul>
          </div>
        </div>
        <div className="col-md-1">
          <div className="ftco-footer-widget  mb-4">
          <h2 className="ftco-heading-2">Policy</h2>
            <ul className="list-unstyled">
            <li>
                <a href="/privacypolicy" className="py-2 d-block">
                  Privacy
                </a>
              </li>
              <li>
                <a href="/termsandcondition" className="py-2 d-block">
                  Terms & Condition
                </a>
              </li>
              <li>
                <a href="/refundpolicy" className="py-2 d-block">
                  Refund & Cancellation 
                </a>
              </li>
              <li>
                <a href="/pricing" className="py-2 d-block">
                  Pricing
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-3">
          <div className="ftco-footer-widget mb-4">
            <h2 className="ftco-heading-2">Address</h2>
            <div className="block-23 mb-3"> 
              <ul>
                <li>
                <a href="https://www.google.com/maps/place/Tirumalai+Nagar,+Perungudi,+Chennai,+Tamil+Nadu+600096/@12.961439,80.2410986,849m/data=!3m2!1e3!4b1!4m6!3m5!1s0x3a525d0e1a31d933:0xdf10626c5cec021d!8m2!3d12.9619055!4d80.2436324!16s%2Fg%2F1tdhcflq!5m1!1e1?authuser=0&entry=ttu&g_ep=EgoyMDI1MDIwNS4xIKXMDSoASAFQAw%3D%3D"  target="_blank"
    rel="noopener noreferrer">
                <img 
                  src={Location} 
                  alt="Email Icon" 
                  style={{ width: '30px', marginRight: '5px' }} 
                  />
                  <span className="text" style={{paddingLeft:"5px"}}>
                  Plot No 87, 1st Floor, 4th Cross Street, Thirumalai Nagar, Perungudi, Chennai 600096
                  </span>
                  </a>
                </li>
                <li>
                <a href="tel:+919840292498"  target="_blank"
    rel="noopener noreferrer">
                <img 
                  src={Phone} 
                  alt="Email Icon" 
                  style={{ width: '30px', marginRight: '5px' }} 
                  /> 
                  <span className="text" style={{ paddingLeft:"5px"}}>+91 98402 92498</span>
                </a>
              </li>
              <li>
                <a href="mailto:projects@datadna.in"  target="_blank"
    rel="noopener noreferrer">
                <img 
                  src={Email} 
                  alt="Email Icon" 
                  style={{ width: '30px', marginRight: '5px' }} 
                  />
                  <span className="text"  style={{ paddingLeft:"5px"}}>projects@datadna.in</span>
                </a>
              </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
     
    </div>
    <div className="footer-icons-wrapper">
          <FloatingIcons isFooter={true} />
        </div>
  </footer>
    );
}


export default Footer1;